import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TrendingUp from '@mui/icons-material/TrendingUp';
import ClearAll from '@mui/icons-material/ClearAll';
import Gesture from '@mui/icons-material/Gesture';
import Send from '@mui/icons-material/Send'
import LinkIcon from '@mui/icons-material/Link'
import Eject from '@mui/icons-material/Eject'
import Button from '@mui/material/Button';
import DataUsage from '@mui/icons-material/DataUsage'

import Motorcycle from '@mui/icons-material/ZoomIn'
import ZoomIn from '@mui/icons-material/ZoomIn'
import ListAlt from '@mui/icons-material/ListAlt'
import Voicemail from '@mui/icons-material/Voicemail'
import Build from '@mui/icons-material/Build'
import Cake from '@mui/icons-material/Cake'
import Keyboard from '@mui/icons-material/Keyboard'
import VerifiedUser from '@mui/icons-material/VerifiedUser'
import LocalDining from '@mui/icons-material/LocalDining'
import NaturePeople from '@mui/icons-material/NaturePeople'

import Code from '@mui/icons-material/Code'
import Battery30 from '@mui/icons-material/Battery30'
import DonutSmall from '@mui/icons-material/DonutSmall'
import KeyboardHide from '@mui/icons-material/KeyboardHide'

import FindReplace from '@mui/icons-material/FindReplace'
import Unarchive from '@mui/icons-material/Unarchive'
import FormatAlignJustifySharp from '@mui/icons-material/FormatAlignJustifySharp'
import HdSharp from '@mui/icons-material/HdSharp'
import MailOutline from '@mui/icons-material/MailOutline'
import ImageSearch from '@mui/icons-material/ImageSearch'

import RuleTp4 from '../ruletp/RuleTp4';
import KDSetting from './KDSetting'
import MACDSetting from './MACDSetting'
import HappySetting from './HappySetting'
import DMISetting from './DMISetting'
import WilliamSetting from './WilliamSetting'
import EMASetting from './EMASetting'
import BBSetting from './BBSetting'
import HLPSetting from './HLPSetting'


import {
    BASIC_1, BASIC_2, BASIC_3, BASIC_4, BASIC_5, BASIC_6, BASIC_7,
    BASIC_8, BASIC_9, BASIC_10
} from '../ruletp/RuleData'
import {
    BASIC_1_IDS, BASIC_2_IDS, BASIC_3_IDS, BASIC_4_IDS, BASIC_5_IDS, BASIC_6_IDS,
    BASIC_7_IDS, BASIC_8_IDS, BASIC_9_IDS, BASIC_10_IDS
} from '../ruletp/RuleData'

import {
    TECH_1, TECH_2, TECH_3, TECH_4, TECH_5, TECH_6, TECH_7, TECH_8,
    TECH_9, TECH_10, TECH_11, TECH_12, TECH_13, TECH_14, TECH_15, TECH_16, TECH_17
} from '../ruletp/RuleData'
import {
    TECH_1_IDS, TECH_2_IDS, TECH_3_IDS, TECH_4_IDS, TECH_5_IDS, TECH_6_IDS,
    TECH_7_IDS, TECH_8_IDS, TECH_9_IDS, TECH_10_IDS, TECH_11_IDS, TECH_12_IDS,
    TECH_13_IDS, TECH_14_IDS, TECH_15_IDS, TECH_16_IDS, TECH_17_IDS
} from '../ruletp/RuleData'

import { MONEY_1, MONEY_2, MONEY_3, MONEY_4, MONEY_5, MONEY_6, MONEY_7 }
    from '../ruletp/RuleData'
import {
    MONEY_1_IDS, MONEY_2_IDS, MONEY_3_IDS, MONEY_4_IDS,
    MONEY_5_IDS, MONEY_6_IDS, MONEY_7_IDS
} from '../ruletp/RuleData'

import {
    LAZY_1, LAZY_2, LAZY_3, LAZY_4, LAZY_5, LAZY_6, LAZY_7, LAZY_8,
    LAZY_9, LAZY_10, LAZY_11, LAZY_12, LAZY_13
} from '../ruletp/RuleData'
import {
    LAZY_1_IDS, LAZY_2_IDS, LAZY_3_IDS, LAZY_4_IDS, LAZY_5_IDS, LAZY_6_IDS,
    LAZY_7_IDS, LAZY_8_IDS, LAZY_9_IDS, LAZY_10_IDS, LAZY_11_IDS, LAZY_12_IDS,
    LAZY_13_IDS
} from '../ruletp/RuleData'

import { EASY_1, EASY_1_IDS, SHAPE_1, SHAPE_1_IDS, SHAPE_2, SHAPE_2_IDS, BROKER_1, BROKER_1_IDS } from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';


const useStyles = makeStyles(theme => ({
}));


export default function AllStrageRules(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panelx');

    const [open1, setOpen1] = React.useState(false)
    const [open2, setOpen2] = React.useState(false)
    const [open3, setOpen3] = React.useState(false)
    const [open4, setOpen4] = React.useState(false)
    const [open5, setOpen5] = React.useState(false)
    const [open6, setOpen6] = React.useState(false)
    const [open7, setOpen7] = React.useState(false)
    const [open8, setOpen8] = React.useState(false);

    const handleClickOpen8 = () => {
        setOpen8(true)
    };

    const handleClose8 = () => {
        setOpen8(false)
    };

    const handleOk8 = () => {
        setOpen8(false)
        if (props.updateRule != null) props.updateRule()
    };



    const handleClickOpen7 = () => {
        setOpen7(true)
    };

    const handleClose7 = () => {
        setOpen7(false)
    };

    const handleOk7 = () => {
        setOpen7(false)
        if (props.updateRule != null) props.updateRule()
    };

    const handleClickOpen6 = () => {
        setOpen6(true)
    };

    const handleClose6 = () => {
        setOpen6(false)
    };

    const handleOk6 = () => {
        setOpen6(false)
        if (props.updateRule != null) props.updateRule()
    };


    const handleClickOpen5 = () => {
        setOpen5(true)
    };

    const handleClose5 = () => {
        setOpen5(false)
    };

    const handleOk5 = () => {
        setOpen5(false)
        if (props.updateRule != null) props.updateRule()
    };

    const handleClickOpen4 = () => {
        setOpen4(true)
    };

    const handleClose4 = () => {
        setOpen4(false)
    };

    const handleOk4 = () => {
        setOpen4(false)
        if (props.updateRule != null) props.updateRule()
    };


    const handleClickOpen3 = () => {
        setOpen3(true)
    };

    const handleClose3 = () => {
        setOpen3(false)
    };

    const handleOk3 = () => {
        setOpen3(false)
        if (props.updateRule != null) props.updateRule()
    };

    const handleClickOpen2 = () => {
        setOpen2(true)
    };

    const handleClose2 = () => {
        setOpen2(false)
    };

    const handleOk2 = () => {
        setOpen2(false)
        if (props.updateRule != null) props.updateRule()
    };

    const handleClickOpen1 = () => {
        setOpen1(true)
    };

    const handleClose1 = () => {
        setOpen1(false)
    };

    const handleOk = () => {
        setOpen1(false)
        if (props.updateRule != null) props.updateRule()
    };


    const handleChange = panel => (event, expanded) => {
        setExpanded(expanded ? panel : false)
    };

    const isEASYOn1 = props.rows.some(rule => EASY_1_IDS.includes(rule.id));

    const isSHAPEOn1 = props.rows.some(rule => SHAPE_1_IDS.includes(rule.id));
    const isSHAPEOn2 = props.rows.some(rule => SHAPE_2_IDS.includes(rule.id));

    const isBROKEROn1 = props.rows.some(rule => BROKER_1_IDS.includes(rule.id));

    const isBASICOn1 = props.rows.some(rule => BASIC_1_IDS.includes(rule.id));
    const isBASICOn2 = props.rows.some(rule => BASIC_2_IDS.includes(rule.id));
    const isBASICOn3 = props.rows.some(rule => BASIC_3_IDS.includes(rule.id));
    const isBASICOn4 = props.rows.some(rule => BASIC_4_IDS.includes(rule.id));
    const isBASICOn5 = props.rows.some(rule => BASIC_5_IDS.includes(rule.id));
    const isBASICOn6 = props.rows.some(rule => BASIC_6_IDS.includes(rule.id));
    const isBASICOn7 = props.rows.some(rule => BASIC_7_IDS.includes(rule.id));
    const isBASICOn8 = props.rows.some(rule => BASIC_8_IDS.includes(rule.id));
    const isBASICOn9 = props.rows.some(rule => BASIC_9_IDS.includes(rule.id));
    const isBASICOn10 = props.rows.some(rule => BASIC_10_IDS.includes(rule.id));

    const isTECHOn1 = props.rows.some(rule => TECH_1_IDS.includes(rule.id));
    const isTECHOn2 = props.rows.some(rule => TECH_2_IDS.includes(rule.id));
    const isTECHOn3 = props.rows.some(rule => TECH_3_IDS.includes(rule.id));
    const isTECHOn4 = props.rows.some(rule => TECH_4_IDS.includes(rule.id));
    const isTECHOn5 = props.rows.some(rule => TECH_5_IDS.includes(rule.id));
    const isTECHOn6 = props.rows.some(rule => TECH_6_IDS.includes(rule.id));
    const isTECHOn7 = props.rows.some(rule => TECH_7_IDS.includes(rule.id));
    const isTECHOn8 = props.rows.some(rule => TECH_8_IDS.includes(rule.id));
    const isTECHOn9 = props.rows.some(rule => TECH_9_IDS.includes(rule.id));
    const isTECHOn10 = props.rows.some(rule => TECH_10_IDS.includes(rule.id));
    const isTECHOn11 = props.rows.some(rule => TECH_11_IDS.includes(rule.id));
    const isTECHOn12 = props.rows.some(rule => TECH_12_IDS.includes(rule.id));
    const isTECHOn13 = props.rows.some(rule => TECH_13_IDS.includes(rule.id));
    const isTECHOn14 = props.rows.some(rule => TECH_14_IDS.includes(rule.id));
    const isTECHOn15 = props.rows.some(rule => TECH_15_IDS.includes(rule.id));
    const isTECHOn16 = props.rows.some(rule => TECH_16_IDS.includes(rule.id));
    const isTECHOn17 = props.rows.some(rule => TECH_17_IDS.includes(rule.id));

    const isMoneyOn1 = props.rows.some(rule => MONEY_1_IDS.includes(rule.id));
    const isMoneyOn2 = props.rows.some(rule => MONEY_2_IDS.includes(rule.id));
    const isMoneyOn3 = props.rows.some(rule => MONEY_3_IDS.includes(rule.id));
    const isMoneyOn4 = props.rows.some(rule => MONEY_4_IDS.includes(rule.id));
    const isMoneyOn5 = props.rows.some(rule => MONEY_5_IDS.includes(rule.id));
    const isMoneyOn6 = props.rows.some(rule => MONEY_6_IDS.includes(rule.id));
    const isMoneyOn7 = props.rows.some(rule => MONEY_7_IDS.includes(rule.id));

    const isLAZYOn1 = props.rows.some(rule => LAZY_1_IDS.includes(rule.id));
    const isLAZYOn2 = props.rows.some(rule => LAZY_2_IDS.includes(rule.id));
    const isLAZYOn3 = props.rows.some(rule => LAZY_3_IDS.includes(rule.id));
    const isLAZYOn4 = props.rows.some(rule => LAZY_4_IDS.includes(rule.id));
    const isLAZYOn5 = props.rows.some(rule => LAZY_5_IDS.includes(rule.id));
    const isLAZYOn6 = props.rows.some(rule => LAZY_6_IDS.includes(rule.id));
    const isLAZYOn7 = props.rows.some(rule => LAZY_7_IDS.includes(rule.id));
    const isLAZYOn8 = props.rows.some(rule => LAZY_8_IDS.includes(rule.id));
    const isLAZYOn9 = props.rows.some(rule => LAZY_9_IDS.includes(rule.id));
    const isLAZYOn10 = props.rows.some(rule => LAZY_10_IDS.includes(rule.id));
    const isLAZYOn11 = props.rows.some(rule => LAZY_11_IDS.includes(rule.id));
    const isLAZYOn12 = props.rows.some(rule => LAZY_12_IDS.includes(rule.id));
    const isLAZYOn13 = props.rows.some(rule => LAZY_13_IDS.includes(rule.id));

    // useEffect(() => {
    //     if (props.srow != null) {
    //         setprops.rows(props.srow.rules)
    //     }
    // }, [props.srow]);


    return (
        <div>
            <KDSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></KDSetting>
            <MACDSetting open={open2} handleClose={handleClose2} handleOk={handleOk2}></MACDSetting>
            <HappySetting open={open3} handleClose={handleClose3} handleOk={handleOk3}></HappySetting>
            <DMISetting open={open4} handleClose={handleClose4} handleOk={handleOk4}></DMISetting>
            <WilliamSetting open={open5} handleClose={handleClose5} handleOk={handleOk5}></WilliamSetting>
            <EMASetting open={open6} handleClose={handleClose6} handleOk={handleOk6}></EMASetting>
            <BBSetting open={open7} handleClose={handleClose7} handleOk={handleOk7}></BBSetting>
            <HLPSetting open={open8} handleClose={handleClose8} handleOk={handleOk8}></HLPSetting>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'EASYOn1'}
                onChange={handleChange('EASYOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isEASYOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <LinkIcon  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography  variant="subtitle1" >輕鬆選</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {EASY_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn1'}
                onChange={handleChange('BASICOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <TrendingUp  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">營收</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn10'}
                onChange={handleChange('BASICOn10')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn10 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Voicemail  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">EPS</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_10.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn2'}
                onChange={handleChange('BASICOn2')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn2 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ClearAll  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">股利、股本、殖利率</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_2.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn3'}
                onChange={handleChange('BASICOn3')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn3 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Gesture  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">本益比、毛利率、類別</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_3.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn4'}
                onChange={handleChange('BASICOn4')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn4 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Send  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">股東權益報酬率、資產報酬率、負債比率、本業收入、利息保障</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_4.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn5'}
                onChange={handleChange('BASICOn5')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn5 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Keyboard  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">財報</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_5.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn6'}
                onChange={handleChange('BASICOn6')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn6 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <LinkIcon  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">營業利率、稅後淨利率、股價淨值比</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_6.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn7'}
                onChange={handleChange('BASICOn7')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn7 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Eject  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">流動比率、速動比率、價值估算、合約負債</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_7.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn8'}
                onChange={handleChange('BASICOn8')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn8 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <DataUsage  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">可轉換公司債、波動率、Beta</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_8.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BASICOn9'}
                onChange={handleChange('BASICOn9')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBASICOn9 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <LocalDining  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">ETF、自選股、期貨</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BASIC_9.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn1'}
                onChange={handleChange('TECHOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Motorcycle  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">KDJ1
                    </Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen1}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn12'}
                onChange={handleChange('TECHOn12')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn12 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Motorcycle  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">KDJ2
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_12.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn2'}
                onChange={handleChange('TECHOn2')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn2 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <VerifiedUser  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">MACD1
                    </Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen2}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_2.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn13'}
                onChange={handleChange('TECHOn13')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn13 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <VerifiedUser  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">MACD2
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_13.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn3'}
                onChange={handleChange('TECHOn3')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn3 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ZoomIn  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">均線1</Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen6}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_3.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn4'}
                onChange={handleChange('TECHOn4')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn4 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ZoomIn  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">均線2</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_4.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn14'}
                onChange={handleChange('TECHOn14')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn14 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ZoomIn  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">均線3</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_14.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn5'}
                onChange={handleChange('TECHOn5')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn5 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Build  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">日K線</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_5.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>



            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn16'}
                onChange={handleChange('TECHOn16')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn16 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Build  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">周K線</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_16.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn17'}
                onChange={handleChange('TECHOn17')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn17 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Build  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">月K線</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_17.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn6'}
                onChange={handleChange('TECHOn6')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn6 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Keyboard  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">成交量1</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_6.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn15'}
                onChange={handleChange('TECHOn15')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn15 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Keyboard  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">成交量2</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_15.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn7'}
                onChange={handleChange('TECHOn7')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn7 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Voicemail  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">RSI、威廉指標</Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen5}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_7.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn8'}
                onChange={handleChange('TECHOn8')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn8 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ListAlt  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">股價、大盤</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_8.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn9'}
                onChange={handleChange('TECHOn9')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn9 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Cake  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">糾結線、布林通道、肯特納通道</Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen7}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_9.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn10'}
                onChange={handleChange('TECHOn10')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn10 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <NaturePeople  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">PSY、乖離率、RS、樂活、CCI</Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen3}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_10.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'TECHOn11'}
                onChange={handleChange('TECHOn11')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isTECHOn11 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <LocalDining  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">SAR、OBV、DMI</Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen4}>
                        調整參數
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {TECH_11.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn1'}
                onChange={handleChange('MoneyOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Battery30  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">融資、融券</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn2'}
                onChange={handleChange('MoneyOn2')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn2 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Code  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">三大法人</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_2.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn3'}
                onChange={handleChange('MoneyOn3')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn3 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <KeyboardHide  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">主力、中實戶、券商</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_3.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn4'}
                onChange={handleChange('MoneyOn4')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn4 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <DataUsage  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">大戶、散戶〔多方〕</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_4.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn5'}
                onChange={handleChange('MoneyOn5')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn5 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <DonutSmall  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">大戶、散戶〔空方〕</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_5.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn6'}
                onChange={handleChange('MoneyOn6')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn6 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Build  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">買賣家數差、籌碼集中度、周轉率、券資比</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_6.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'MoneyOn7'}
                onChange={handleChange('MoneyOn7')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isMoneyOn7 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Voicemail  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">八大官股、當沖率、董監事</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {MONEY_7.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>



            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn1'}
                onChange={handleChange('LAZYOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <TrendingUp  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">基本面
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn2'}
                onChange={handleChange('LAZYOn2')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn2 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Unarchive  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">EPS、CMF
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_2.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>



            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn3'}
                onChange={handleChange('LAZYOn3')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn3 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <LinkIcon  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">主力、大戶、散戶、集中度
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_3.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn4'}
                onChange={handleChange('LAZYOn4')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn4 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ClearAll  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">三大法人、八大官股
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_4.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn5'}
                onChange={handleChange('LAZYOn5')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn5 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Gesture  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">大盤、除權息
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_5.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn6'}
                onChange={handleChange('LAZYOn6')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn6 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <MailOutline  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">營收
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_6.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn7'}
                onChange={handleChange('LAZYOn7')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn7 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Send  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">內涵價值、樂活五線譜、串聯指標
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_7.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn8'}
                onChange={handleChange('LAZYOn8')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn8 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <VerifiedUser  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">分點券商、三方指標
                    </Typography>
                    <Typography size="small" variant="caption" style={{ fontSize: 10,  marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen8}>
                        調整參數
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_8.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>



            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn9'}
                onChange={handleChange('LAZYOn9')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn9 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <FormatAlignJustifySharp  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">KD、趨勢線、衝刺指標
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_9.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn10'}
                onChange={handleChange('LAZYOn10')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn10 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Eject  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">布林通道、乖離率、支撐壓力、寶塔線
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_10.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn11'}
                onChange={handleChange('LAZYOn11')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn11 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <HdSharp  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">葛蘭碧法則、九轉序列、新三價線、期望指標
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_11.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn12'}
                onChange={handleChange('LAZYOn12')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn12 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <ImageSearch  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">支壓圖，成長圖，風險與報酬率
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_12.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'LAZYOn13'}
                onChange={handleChange('LAZYOn13')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isLAZYOn13 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <FindReplace  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">異常訊號
                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="懶人選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {LAZY_13.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'BROKEROn1'}
                onChange={handleChange('BROKEROn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isBROKEROn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Cake  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">券商</Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="券商選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {BROKER_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'SHAPEOn1'}
                onChange={handleChange('SHAPEOn1')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isSHAPEOn1 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Voicemail  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">轉折波型態

                    </Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="型態選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {SHAPE_1.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>


            <ExpansionPanel
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === 'SHAPEOn2'}
                onChange={handleChange('SHAPEOn2')}
            >
                <ExpansionPanelSummary style={{
                    backgroundColor: isSHAPEOn2 ? enableColor : disableColor,
                    borderBottomStyle: 'solid',
                    borderWidth: 1,
                    borderColor: "#e0e0e0",
                }}>
                    <Gesture  style={{ fontSize: 30, marginRight: 10 }} />
                    <Typography variant="subtitle1">量價型態</Typography>
                    <Chip variant="outlined" style={{ marginLeft: 5, fontSize: 12 }} size="small" color="secondary" label="型態選股-訂閱" />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                    {SHAPE_2.map(row => {
                        return <RuleTp4 key={row.id} row={row} ruleChange={props.updateRule} rules={props.rows} />
                    })}

                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}