import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function KDSetting(props) {
  //   const [open, setOpen] = React.useState(false);

  const yref = React.createRef();
  const hrref = React.createRef();
  const drref = React.createRef();
  const jrref = React.createRef();

  const handleOk = () => {
    if (yref.current.value != null && yref.current.value != "")
      localStorage.setItem('kdp', parseInt(yref.current.value));

    if (hrref.current.value != null && hrref.current.value != "")
      localStorage.setItem('kdr', parseFloat(hrref.current.value));

    if (drref.current.value != null && drref.current.value != "")
      localStorage.setItem('kdk', parseFloat(drref.current.value));

    if (jrref.current.value != null && jrref.current.value != "") {
      localStorage.setItem('kdjv', jrref.current.value);
      if (jrref.current.value == '3D-2K') {
        localStorage.setItem('kdj', 0);
      } else {
        localStorage.setItem('kdj', 1);
      }
    }

    props.handleOk()
  };


  function yy() {
    return localStorage.getItem('kdp') === null ? 9 : localStorage.getItem('kdp')
  }

  function hR() {
    return localStorage.getItem('kdr') === null ? 3.0 : localStorage.getItem('kdr')
  }

  function hkdk() {
    return localStorage.getItem('kdk') === null ? 3.0 : localStorage.getItem('kdk')
  }

  function kdj() {
    return localStorage.getItem('kdjv') === null ? "3D-2K" : localStorage.getItem('kdjv')
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">KD參數</DialogTitle>

      <DialogContent>

        <br />
        <TextField
          autoFocus
          margin="dense"
          id="y"
          label="期間(預設:9)"
          type="text"
          fullWidth
          defaultValue={yy()}
          inputRef={yref}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="hr"
          label="RSVt權值(預設:3.0)"
          type="text"
          fullWidth
          defaultValue={hR()}
          inputRef={hrref}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          margin="dense"
          id="kdk"
          label="Kt權值(預設:3.0)"
          type="text"
          fullWidth
          defaultValue={hkdk()}
          inputRef={drref}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          margin="dense"
          id="kdj"
          label="J(3D-2K或3K-2D)"
          type="text"
          fullWidth
          defaultValue={kdj()}
          inputRef={jrref}
          InputLabelProps={{
            shrink: true,
          }}
        />


      </DialogContent>
      {localStorage.getItem('noad') === "1" ? <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          取消
          </Button>
        <Button onClick={handleOk} color="primary">
          確定
          </Button>
      </DialogActions> : <DialogActions>
          <Button onClick={props.handleClose} >
            關閉 (VIP專屬)
          </Button>
        </DialogActions>}
    </Dialog>
  );
}
