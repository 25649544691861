const Piece1 = [
    {id: "-1", value: "不限定張"},    
    {id: "0", value: "0張"},  
    {id: "5", value: "5張"},
    {id: "10", value: "10張"},
    {id: "15", value: "15張"},
    {id: "20", value: "20張"},
    {id: "30", value: "30張"},
    {id: "40", value: "40張"},
    {id: "50", value: "50張"},
    {id: "100", value: "100張"},
    {id: "200", value: "200張"},
    {id: "400", value: "400張"},
    {id: "600", value: "600張"},
    {id: "800", value: "800張"},    
    {id: "1000", value: "1,000張"},
    {id: "1500", value: "1,500張"},    
    {id: "2000", value: "2,000張"},
    {id: "2500", value: "2,500張"},
    {id: "3000", value: "3,000張"},
  ];  
  
  export default Piece1  