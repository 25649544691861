import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getId } from '../util/ID'
import AlertD from '../util/AlertD';
import SearchBroker from './SearchBroker';

export default function Re2Condition(props) {
    const [open, setOpen] = React.useState(false);
    const [money, setMoney] = React.useState(null);
    const [piece, setPiece] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [bid, setBID] = React.useState(null);
    const [calDays, setCalDays] = React.useState(1);
    const [baseDays, setBaseDays] = React.useState(30);
    const [tp, setTP] = React.useState(0);
    const [times, setTimes] = React.useState(30);
    const [bidValue, setBidValue] = React.useState(null);
    const [minBuy, setMinBuy] = React.useState(10000);

    

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (bid == null) {
            setAlertMsg("請輸入分點")
            setShowAlert(true)
        } else {
            localStorage.setItem("rec2_bid", bid)
            localStorage.setItem("rec2_cal_days", calDays)
            localStorage.setItem("rec2_base_days", baseDays)
            localStorage.setItem("rec2_tp", tp)
            localStorage.setItem("rec2_times", times)
            localStorage.setItem("rec2_min_buy", minBuy)

            run();
            
        }
    };

    const run = () => {
        if(localStorage.getItem("rec2_bid") != null) {
            let bid = localStorage.getItem("rec2_bid")
            let calDays = localStorage.getItem("rec2_cal_days")
            let baseDays = localStorage.getItem("rec2_base_days")
            let tp = localStorage.getItem("rec2_tp")
            let times = localStorage.getItem("rec2_times")
            let minBuy = localStorage.getItem("rec2_min_buy")
        
            let param = {
                sn: getId(),
                tp: tp,
                bid: bid,
                cur_days: calDays,
                base_days: baseDays,
                min_buy: minBuy,
                times: times
            }
    
            if (localStorage.getItem("chip_lic") != null) {
                param['lic'] = localStorage.getItem("chip_lic")
            }
    
    
            fetch("/chip/v1/ten/run", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if(result.status === 0) {
                            // setAlertMsg("執行需要幾分鐘的時間，可以按下＂更新結果＂來進行確認。")
                            // setShowAlert(true)
                            handleClose()
                        } else {
                            setAlertMsg("執行失敗，請稍後再嘗試")
                            setShowAlert(true)                            
                        }
                    }
                );        
        } else {
            setAlertMsg("請先設定完條件再來查詢。")
            setShowAlert(true)
        }
    }


    useEffect(() => {
        if (props.open) {
            setOpen(true)

            if(localStorage.getItem("rec2_bid") != null) {
                setBID(localStorage.getItem("rec2_bid"))
            }

            if(localStorage.getItem("rec2_bid_option") != null) {
                setBidValue(localStorage.getItem("rec2_bid_option"))
            }
            if(localStorage.getItem("rec2_cal_days") != null) setCalDays(parseInt(localStorage.getItem("rec2_cal_days")))
            if(localStorage.getItem("rec2_base_days") != null) setBaseDays(parseInt(localStorage.getItem("rec2_base_days")))
            if(localStorage.getItem("rec2_tp") != null) setTP(parseInt(localStorage.getItem("rec2_tp")))
            if(localStorage.getItem("rec2_times") != null) setTimes(parseInt(localStorage.getItem("rec2_times")))
            if(localStorage.getItem("rec2_min_buy") != null) setMinBuy(parseInt(localStorage.getItem("rec2_min_buy")))
        } else setOpen(false)
    }, [props.open]);

    const getBidOptionValue = () => {
        if(bidValue != null) {
            return JSON.parse(bidValue).label
        } else if(bid != null) {
            return bid
        } else return "X"
    }

    const handleCalDays = (event) => {
        setCalDays(event.target.value)
    };

    const handleBaseDays = (event) => {
        setBaseDays(event.target.value)
    };

    const handleTp = (event) => {
        setTP(event.target.value)
    };

    const handleTimes = (event) => {
        setTimes(event.target.value)
    };

    const bidOption = (value) => {
        localStorage.setItem("rec2_bid_option", value)
    };

    const getTp = (tp) => {
        if(tp === 0) return "買入"
        else if(tp === 1) return "賣出"
        else if(tp === 2) return "買賣超"
        else if(tp === 3) return "買超"
        else if(tp === 4) return "賣超"                
        else return "買賣超"        
    }
    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"條件"}
                </DialogTitle>
                <DialogContent sx={{}}>
                    <Typography variant='body2'>1. 分點{getBidOptionValue()}，最近{calDays}日的{getTp(tp)}均量至少要{minBuy}股並比過去{baseDays}日的均量還多出{times}倍。</Typography>
                    <SearchBroker bidOption={bidOption} setSID={setBID} bid={bid} bidValue={bidValue} ></SearchBroker>

                    <FormControl variant="filled" fullWidth  >
                        <InputLabel id="calDays_label">近日</InputLabel>
                        <Select
                            labelId="calDays_label"
                            id="calDays"
                            value={calDays}
                            label="calDays"
                            onChange={handleCalDays}
                        >
                            {Array.from({ length: 20 }, (_, i) => i + 1).map(index => {
                                return <MenuItem key={index} value={index}>{index}日</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" fullWidth  >
                        <InputLabel id="tp_label">買/賣</InputLabel>
                        <Select
                            labelId="tp_label"
                            id="tp"
                            value={tp}
                            label="tp"
                            onChange={handleTp}
                        >
                            <MenuItem key={"b"} value={0}>買入</MenuItem>
                            <MenuItem key={"s"} value={1}>賣出</MenuItem>
                            <MenuItem key={"bs"} value={2}>買賣超</MenuItem>
                            <MenuItem key={"ob"} value={3}>買超</MenuItem>
                            <MenuItem key={"os"} value={4}>賣超</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" fullWidth  >
                        <InputLabel id="minBuy_label">最低量</InputLabel>
                        <Select
                            labelId="minBuy_label"
                            id="minBuy"
                            value={minBuy}
                            label="minBuy"
                            onChange={event => setMinBuy(event.target.value)}
                        >
                            {Array.from({ length: 200 }, (_, i) => i + 1).map(index => {
                                return <MenuItem key={index * 1000} value={index * 1000}>{index*1000}股</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled" fullWidth  >
                        <InputLabel id="baseDays_label">過去</InputLabel>
                        <Select
                            labelId="baseDays_label"
                            id="baseDays"
                            value={baseDays}
                            label="baseDays"
                            onChange={handleBaseDays}
                        >
                            {Array.from({ length: 60 }, (_, i) => i + 1).map(index => {
                                return <MenuItem key={index} value={index}>{index}日</MenuItem>
                            })}
                        </Select>
                    </FormControl>


                    <FormControl variant="filled" fullWidth  >
                        <InputLabel id="times_label">倍數</InputLabel>
                        <Select
                            labelId="times_label"
                            id="times"
                            value={times}
                            label="times"
                            onChange={handleTimes}
                        >
                            {Array.from({ length: 100 }, (_, i) => i + 1).map(index => {
                                return <MenuItem key={index} value={index}>{index}倍</MenuItem>
                            })}
                        </Select>
                    </FormControl>



                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                    </Stack>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    {/* disabled={localStorage.getItem("noad") != "1"} */}
                    <Button onClick={handleOKClose} disabled={localStorage.getItem("noad") != "1"}>
                        執行條件
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
