const DayYear = [
    {id: "10", value: "10天"},    
    {id: "20", value: "20天"},
    {id: "60", value: "60天"},
    {id: "120", value: "120天"},        
    {id: "180", value: "180天"},
    {id: "240", value: "240天"},
    {id: "260", value: "1年"},
    {id: "520", value: "2年"},    
    {id: "780", value: "3年"},
    {id: "1040", value: "4年"},    
    {id: "1300", value: "5年"},
    {id: "1560", value: "6年"},
];  
  
  export default DayYear