const KLine = [
    {id: "0", value: "不限定"},    
    {id: "1", value: "長紅K", ig: "a12.png"},
    {id: "2", value: "長黑K", ig: "a13.png"},
    {id: "3", value: "小紅K", ig: "a14.png"},
    {id: "4", value: "小黑K", ig: "a15.png"},    
    {id: "5", value: "有下影線紅K", ig: "a16.png"},
    {id: "6", value: "有下影線黑K", ig: "a17.png"},
    {id: "7", value: "有上影線紅K", ig: "a18.png"},
    {id: "8", value: "有上影線黑K", ig: "a19.png"},    
    {id: "9", value: "十字線", ig: "a20.png"},
    {id: "10", value: "墓塔", ig: "a21.png"},       
  ];  
  export default KLine  