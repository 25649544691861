const Month12Year7 = [
    {id: "1", value: "1個月"},    
    {id: "2", value: "2個月"},
    {id: "3", value: "3個月"},
    {id: "4", value: "4個月"}, 
    {id: "5", value: "5個月"},
    {id: "6", value: "6個月"},
    {id: "7", value: "7個月"},
    {id: "8", value: "8個月"},
    {id: "9", value: "9個月"},
    {id: "10", value: "10個月"},
    {id: "11", value: "11個月"},      
    {id: "12", value: "1年"},    
    {id: "24", value: "2年"},
    {id: "36", value: "3年"},
    {id: "48", value: "4年"},
    {id: "60", value: "5年"},
    {id: "72", value: "6年"},
    {id: "84", value: "7年"}
  ];  
  
  export default Month12Year7  