const Dollar0_50 = [
    {id: "0", value: "0元"},    
    {id: "1", value: "1元"},
    {id: "2", value: "2元"},
    {id: "3", value: "3元"},
    {id: "4", value: "4元"},
    {id: "5", value: "5元"},
    {id: "6", value: "6元"},
    {id: "7", value: "7元"},
    {id: "8", value: "8元"},
    {id: "9", value: "9元"},
    {id: "10", value: "10元"},
    {id: "11", value: "11元"},
    {id: "12", value: "12元"},
    {id: "13", value: "13元"},
    {id: "14", value: "14元"},
    {id: "15", value: "15元"},        
    {id: "20", value: "20元"},
    {id: "50", value: "50元"}
  ];  
  
  export default Dollar0_50  