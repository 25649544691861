const NumberN1000 = [
  { id: "-500000000", value: "-500,000,000" },
  { id: "-100000000", value: "-100,000,000" },
  { id: "-50000000", value: "-50,000,000" },
  { id: "-40000000", value: "-40,000,000" },
  { id: "-30000000", value: "-30,000,000" },
  { id: "-20000000", value: "-20,000,000" },  
  { id: "-10000000", value: "-10,000,000" },
  { id: "-5000000", value: "-5,000,000" },
  { id: "-4000000", value: "-4,000,000" },
  { id: "-3000000", value: "-3,000,000" },
  { id: "-2000000", value: "-2,000,000" },
  { id: "-1000000", value: "-1,000,000" },
  { id: "-500000", value: "-500,000" },
  { id: "-100000", value: "-100,000" },
  { id: "-50000", value: "-50,000" },
  { id: "-10000", value: "-10,000" },
  { id: "-5000", value: "-5000" },
  { id: "-3000", value: "-3000" },
  { id: "-1000", value: "-1000" },
  { id: "-500", value: "-500" },
  { id: "0", value: "0" },
  { id: "500", value: "500" },
  { id: "1000", value: "1000" },
  { id: "3000", value: "3000" },
  { id: "5000", value: "5000" },
  { id: "10000", value: "10,000" },
  { id: "50000", value: "50,000" },
  { id: "100000", value: "100,000" },
  { id: "500000", value: "500,000" },
  { id: "1000000", value: "1,000,000" },
  { id: "2000000", value: "2,000,000" },
  { id: "3000000", value: "3,000,000" },
  { id: "4000000", value: "4,000,000" },
  { id: "5000000", value: "5,000,000" },
  { id: "6000000", value: "6,000,000" },
  { id: "7000000", value: "7,000,000" },
  { id: "8000000", value: "8,000,000" },
  { id: "9000000", value: "9,000,000" },
  { id: "10000000", value: "10,000,000" },
  { id: "20000000", value: "20,000,000" },
  { id: "30000000", value: "30,000,000" },    
  { id: "50000000", value: "50,000,000" },
  { id: "100000000", value: "100,000,000" },
  { id: "500000000", value: "50,000,000" },
];

export default NumberN1000