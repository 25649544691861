import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header + 10, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <Box sx={{ pl: 2, pr: 2 }}>
                {/* <Typography variant='h5' fontWeight={"bold"}>何謂動能投資法</Typography>
                <Typography variant='body1'>動能投資法是由Andreas F. Clenow所發明</Typography> */}

                <Typography sx={{ mt: 2, textAlign: "center" }} variant='h4' fontWeight={"bold"}>說明文件</Typography>
                {/* <Typography sx={{ mt: 2 }} variant='body1' ></Typography> */}

                <Typography sx={{ mt: 5 }} variant='h5' fontWeight={"bold"}>分點挖土機是什麼?</Typography>
                <Typography variant='body1'>
                    分點挖土機主要旨在協助使用者迅速找到券商布局的關鍵股票。這項工具涵蓋了近1800支股票與800家券商之間的大約一百多萬種組合，從中尋找關鍵分點的方向。以下是幾種尋找關鍵分點的方法：
                    <p></p>
                    <li>買賣超佔交易量比例較大的分點：尋找在某段時間內買賣超佔總成交量比例較大的分點。這表示該分點可能更為重要。</li>
<br/>
                    <li>主力分點買賣金額較大的股票：分析某段時間內前15大主力分點的總買賣金額排名。這有助於了解資金流入最多的股票。</li>
                    <br/>
                    <li>買賣超成長明顯的分點：比較某分點在最近一段時間內的買賣超與之前的數據，查看其成長幅度。增長明顯的分點可能代表買進力量增強。</li>
                    <br/>
                    <li>買賣超持續天數較多的分點：觀察某分點在一段時間內買賣超的總天數排名。持續多天的買超可能表示市場看多情緒持續。</li>
                    <br/>
                    <li>庫存獲利情況：分析某段時間內分點持有的股票的獲利排名。</li>

                    <p></p>
                </Typography>


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>策略說明</Typography>
                <Typography variant='h6'>多方</Typography>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>買超成交比：這是指分點買超股數與總成交量的比例排名。當分點買超佔成交量比例越大時，這表示該分點越具關鍵性。需要注意的是，總成交量不宜過低，以確保分析結果的可靠性。</Typography>
                    <Typography variant='body1'>買超主力金額：分析某段時間內股票前15大主力分點的總買超金額排名。這能夠揭示哪些股票受到最多資金流入。
                    </Typography>

                    <Typography variant='body1'>買超天數：評估某段時間內分點買超總天數的排名。買超天數越多，代表市場對該股持續看多。</Typography>
                    <Typography variant='body1'>買超成長比：比較最近5天的買超與前5天的數據，找出成長幅度較大的分點。這代表該分點的買進力量正在增強。需要注意的是，總成交量不宜過低，以確保分析結果的可靠性。</Typography>
                    <Typography variant='body1'>庫存營利：分析某段時間內分點持有的股票中，獲利較多的排名。</Typography>
                </Stack>

                <Typography variant='h6'>空方</Typography>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>賣超成交比：這是指分點賣超股數與總成交量的比例排名。當分點賣超佔成交量比例越大時，這表示該分點越具關鍵性。需要注意的是，總成交量不宜過低，以確保分析結果的可靠性。</Typography>
                    <Typography variant='body1'>賣超主力金額：分析某段時間內股票前15大主力分點的總賣超金額排名。這能夠揭示哪些股票受到最多資金流出。
                    </Typography>

                    <Typography variant='body1'>賣超天數：評估某段時間內分點賣超總天數的排名。賣超天數越多，代表市場對該股持續看空。</Typography>
                    <Typography variant='body1'>賣超成長比：比較最近5天的賣超與前5天的數據，找出成長幅度較大的分點。這代表該分點的賣進力量正在增強。需要注意的是，總成交量不宜過低，以確保分析結果的可靠性。</Typography>
                    <Typography variant='body1'>庫存虧損：分析某段時間內分點持有的股票中，虧損較多的排名。</Typography>
                </Stack>

                {/* <Stack direction={"column"}>
                    <Box
                        component="img"
                        sx={{
                            maxHeight: { xs: '50vh', md: '95vh' },
                            maxWidth: { xs: '50wh' },
                        }}
                        alt="book2"
                        src="exp_1.png"
                    />
                </Stack> */}

                {/* <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>策略說明</Typography>
                <Typography variant='body1'></Typography>
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>排序：折溢價跟預期年限可以由高到低或低到高排序</Typography>
                    <Typography variant='body1'>最大預期年限：計算股價隱含多少年限時的最大值，超過此值不顯示。</Typography>
                    <Typography variant='body1'>最小預期年限：預期期限小於此時，不顯示出來。</Typography>
                    <Typography variant='body1'>平均營收成長：用幾年去計算營收平均的成長率是多少。</Typography>
                    <Typography variant='body1'>預估高營收成長：比平均營收成長多少%為高營收成長。</Typography>
                    <Typography variant='body1'>預估低營收成長：比平均營收少多少%為低營收成長</Typography>
                    <Typography variant='body1'>折溢價最小值：折溢價低於多少不顯示，可能此股票不適用此方法評價。</Typography>
                    <Typography variant='body1'>折溢價最大值：折溢價高於多少不顯示，可能此股票不適用此方法評價。</Typography>
                    <Typography variant='body1'>折現率：未來現金折算成現值時的利率</Typography>
                </Stack> */}

                {/* <Stack direction={"column"}>
                    <Box
                        component="img"
                        sx={{
                            maxHeight: { xs: '50vh', md: '95vh' },
                            maxWidth: { xs: '50wh' },
                        }}
                        alt="book3"
                        src="expect_setting.png"
                    />
                                        <Box
                        component="img"
                        sx={{
                            maxHeight: { xs: '50vh', md: '95vh' },
                            maxWidth: { xs: '50wh' },
                        }}
                        alt="book3"
                        src="expect_setting1.png"
                    />
                </Stack> */}

            </Box>





        </Box>
    );
}
