const Day_10_240 = [ 
    {id: "5", value: "5天"}, 
    {id: "10", value: "10天"},
    {id: "15", value: "15天"},
    {id: "20", value: "20天"},
    {id: "40", value: "40天"},
    {id: "60", value: "60天"},
    {id: "80", value: "80天"},    
    {id: "120", value: "120天"},
    {id: "180", value: "180天"},
    {id: "240", value: "240天"},
    {id: "260", value: "260天"},    
    {id: "480", value: "480天"},
    {id: "520", value: "520天"},        
  ];  
  
  export default Day_10_240  