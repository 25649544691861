const Ba = [
    {id: "1", value: "1倍"},    
    {id: "2", value: "2倍"},
    {id: "3", value: "3倍"},
    {id: "4", value: "4倍"},  
    {id: "5", value: "5倍"}, 
    {id: "6", value: "6倍"}, 
    {id: "7", value: "7倍"},  
    {id: "8", value: "8倍"},  
    {id: "9", value: "9倍"},                     
    {id: "10", value: "10倍"},
    {id: "15", value: "15倍"},
    {id: "20", value: "20倍"},
    {id: "25", value: "25倍"},
    {id: "30", value: "30倍"},
    {id: "35", value: "35倍"},
    {id: "40", value: "40倍"},
    {id: "45", value: "45倍"},
    {id: "50", value: "50倍"},
    {id: "60", value: "60倍"},
    {id: "70", value: "70倍"},
    {id: "80", value: "80倍"},
    {id: "90", value: "90倍"}
  ];  
  
  export default Ba  