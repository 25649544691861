const Time4_30 = [
    {id: "4", value: "4倍"},  
    {id: "5", value: "5倍"},      
    {id: "6", value: "6倍"},
    {id: "7", value: "7倍"},
    {id: "8", value: "8倍"},
    {id: "9", value: "9倍"},
    {id: "10", value: "10倍"},
    {id: "11", value: "11倍"},
    {id: "12", value: "12倍"},
    {id: "13", value: "13倍"},
    {id: "14", value: "14倍"},    
    {id: "15", value: "15倍"},
    {id: "16", value: "16倍"},    
    {id: "20", value: "20倍"},
    {id: "24", value: "24倍"},
    {id: "28", value: "28倍"},
    {id: "32", value: "32倍"},
    {id: "36", value: "36倍"}, 
    {id: "40", value: "40倍"},
    {id: "50", value: "50倍"},
    {id: "60", value: "60倍"},
    {id: "70", value: "70倍"},
    {id: "80", value: "80倍"},
    {id: "90", value: "90倍"},
    {id: "100", value: "100倍"},
    {id: "150", value: "150倍"},
    {id: "200", value: "200倍"},
    {id: "250", value: "250倍"},
    {id: "300", value: "300倍"},
    {id: "350", value: "350倍"},
    {id: "400", value: "400倍"},                                         
  ];  
  
  export default Time4_30  