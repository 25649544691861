import React, { useEffect, useRef } from 'react';
import RecItem from './RecItem1'
import { Height } from '../util/ScreenHeight'
import RecSelector from './RecSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import RateDetail from './RateDetail'
import DetailChart1 from './DetailChart1'
import DetailKChart from './DetailKChart'
import { putInpQueryParam } from '../util/ID'

import { Adsense } from '@ctrl/react-adsense';

export default function RecContainer1(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [count, setCount] = React.useState(0)

    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [rules, setRules] = React.useState(null)
    const [openSetting, setOpenSetting] = React.useState(false);
    const [stock, setStock] = React.useState(null);

    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [regTp, setRegTp] = React.useState(-1);
    const [runDay, setRunDay] = React.useState(-1);
    const [stp, setSTP] = React.useState(-1);
    const [searchSID, setSearchSID] = React.useState("");
    const [searchBID, setSearchBID] = React.useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const updateSelector = (selectorTp, selectorYear, selectorSID, selectorBID) => {
        setRegTp(selectorTp)
        setRunDay(selectorYear)
        setSearchSID(selectorSID)
        setSearchBID(selectorBID)
    }

    const updateRules = (rr) => {
        setRules(rr)
    }

    const fetchData = () => {
        setLoading(true)

        // let rules = localStorage.getItem('chip_search_rules') != null ? JSON.parse(localStorage.getItem('chip_search_rules')) : []
        // let sort = localStorage.getItem('chip_search_sort') != null ? localStorage.getItem('chip_search_sort') : '0'


        let param = {
            page: page,
            sn: getId(),
            rules: rules,
            inp: {},
            s: searchSID != null ? searchSID : "",
            b: searchBID != null ? searchBID : "",
            sort: regTp,
            day: runDay
        }

        if (localStorage.getItem("chip_lic") != null) {
            param['lic'] = localStorage.getItem("chip_lic")
        }

        param = putInpQueryParam(param)

        fetch("/chip/v1/app/get1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total)
                            setResult(result.data)
                            setCount(result.count)
                        } else {
                            setCount(0)
                            setTotal(0)
                            setResult([])
                        }
                    } else {
                        setCount(0)
                        setTotal(0)
                        setResult([])
                    }
                }
            );
    }

    useEffect(() => {
        if (regTp != -1 && runDay != -1 &&
            ((searchSID != null && searchSID != "")
                || (searchBID != null && searchBID != ""))) fetchData()
        else if ((searchSID == null || searchSID === "")
            && (searchBID == null || searchBID === "")) {
            setCount(0)
            setTotal(0)
            setResult([])
        }
    }, [page, regTp, runDay, searchBID, searchSID, rules]);



    // useEffect(() => {
    //     if (props.sig != null) {
    //         setPage(1)
    //         setMenu(props.sig)
    //     }
    // }, [props.sig]);


    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.stock === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpenSetting(true)
    }

    const openDetail1 = (sid) => {
        setSid(sid)
        setOpenSetting1(true)
    }

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    const hasAd = () => {
        return localStorage.getItem('noad') != "1"
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting && <RateDetail openDetail2={openDetail2} openDetail1={openDetail1} stock={stock} open={openSetting} close={setOpenSetting}></RateDetail>}
            {openSetting1 && <DetailChart1 regTp={regTp} runDay={runDay} id={id} sid={sid} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}


            <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
                <RecSelector updateRules={updateRules} fetchData={fetchData} setMenu={setMenu} refresh={updateSelector} enableSetting={true} />

                {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>

                    {hasAd() && <Grid key={"ad1"} item xs={12} sm={12} md={12} lg={12}>
                        <Adsense
                            client="ca-pub-8613274553211553"
                            slot="1814586373"
                            style={{ display: 'block' }}
                            layout="in-article"
                            format="fluid"
                        />
                    </Grid>}
                    {result != null && result.map(row => {
                        return <Grid key={row.b + row.s} item xs={12} sm={6} md={4} lg={3}>
                            <RecItem fetchData={fetchData} openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={regTp} key={row.b + row.s} row={row} menu={menu} runDay={runDay} />
                        </Grid>
                    })}
                    {result != null && result.length === 0 &&
                        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                    }

                </Grid>}

                {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
                    {/* (共{count}筆) */}
                </Box>}
            </Box >


        </Box >
    );
}
