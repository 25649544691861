const PHouse = [
  {id: "-10", value: "-10間"},
  {id: "-5", value: "-5間"},
  {id: "0", value: "0間"},    
    {id: "5", value: "5間"},
    {id: "10", value: "10間"},
    {id: "15", value: "15間"},
    {id: "20", value: "20間"},
    {id: "30", value: "30間"},
    {id: "40", value: "40間"},
    {id: "50", value: "50間"},
    {id: "60", value: "60間"},
    {id: "70", value: "70間"},
    {id: "80", value: "80間"},
    {id: "90", value: "90間"},
    {id: "100", value: "100間"},    
    {id: "150", value: "150間"},
    {id: "200", value: "200間"},    
    {id: "250", value: "250間"},
    {id: "300", value: "300間"},
    {id: "400", value: "400間"},
    {id: "500", value: "500間"}        
  ];  
  
  export default PHouse  