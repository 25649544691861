const LongDay = [
    {id: "1", value: "1天"},    
    {id: "2", value: "2天"},
    {id: "3", value: "3天"},
    {id: "4", value: "4天"},  
    {id: "5", value: "5天"}, 
    {id: "6", value: "6天"}, 
    {id: "7", value: "7天"},  
    {id: "8", value: "8天"},  
    {id: "9", value: "9天"},                     
    {id: "10", value: "10天"},
    {id: "15", value: "15天"},
    {id: "20", value: "20天"},
    {id: "25", value: "25天"},
    {id: "30", value: "30天"},
    {id: "35", value: "35天"},
    {id: "40", value: "40天"},
    {id: "45", value: "45天"},
    {id: "50", value: "50天"},
    {id: "60", value: "60天"},
    {id: "70", value: "70天"},
    {id: "80", value: "80天"},
    {id: "90", value: "90天"},
    {id: "100", value: "100天"},
    {id: "120", value: "120天"},
    {id: "140", value: "140天"},
    {id: "160", value: "160天"},
    {id: "180", value: "180天"},
    {id: "200", value: "200天"},
    {id: "220", value: "220天"},
    {id: "240", value: "240天"},                            
  ];  
  
  export default LongDay  