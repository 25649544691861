import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function HappySetting(props) {

    const yref = React.createRef();
    const hrref = React.createRef();
    const drref = React.createRef();
    const erref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('hys', parseInt(yref.current.value));

        if (hrref.current.value != null && hrref.current.value != "")
            localStorage.setItem('psys', parseInt(hrref.current.value));

        if (drref.current.value != null && drref.current.value != "")
            localStorage.setItem('psyl', parseInt(drref.current.value));

        if (erref.current.value != null && erref.current.value != "")
            localStorage.setItem('cci', parseInt(erref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('hys') === null ? 1277 : localStorage.getItem('hys')
    }

    function hR() {
        return localStorage.getItem('psys') === null ? 12 : localStorage.getItem('psys')
    }

    function hkdk() {
        return localStorage.getItem('psyl') === null ? 24 : localStorage.getItem('psyl')
    }

    function ekdk() {
        return localStorage.getItem('cci') === null ? 14 : localStorage.getItem('cci')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">參數設定</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="樂活五線譜天數(預設:1277天 - 3.5年)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="hr"
                    label="PSY短天期(預設:12)"
                    type="text"
                    fullWidth
                    defaultValue={hR()}
                    inputRef={hrref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="dense"
                    id="kdk"
                    label="PSY長天期(預設:24)"
                    type="text"
                    fullWidth
                    defaultValue={hkdk()}
                    inputRef={drref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="dense"
                    id="ekdk"
                    label="CCI天數(預設:14)"
                    type="text"
                    fullWidth
                    defaultValue={ekdk()}
                    inputRef={erref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
                </Button>
                <Button onClick={handleOk} color="primary">
                    確定
                </Button>
            </DialogActions> : <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    關閉（訂閱專屬功能）
                </Button>
            </DialogActions>}
        </Dialog>
    );
}
