const Number1_9 = [
    {id: "1", value: "1"},    
    {id: "2", value: "2"},    
    {id: "3", value: "3"},       
    {id: "4", value: "4"},
    {id: "5", value: "5"},
    {id: "6", value: "6"},
    {id: "7", value: "7"},
    {id: "8", value: "8"},
    {id: "9", value: "9"}   
  ];  
  
  export default Number1_9  