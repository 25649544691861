import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'
import { deepOrange, green, grey, red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import LoveSetting from './LoveSetting'
import KChart from "./KChart"
import BuySellChart from './BuySellChart';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MainBroker from './MainBroker';
import Meta3 from './Meta3';
import { putInpQueryParam } from '../util/ID'
import MainBroker1 from '../rec/MainBroker1'
import Chip from '@mui/material/Chip';

export default function RecItem1(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);

    const [tid, setTid] = React.useState(null);

    const getColor = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "red"
            else if (props.row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            let k = ((props.row.price.i * 100) / (props.row.price.p - props.row.price.i)).toFixed(2)
            if (props.row.price.i > 0) return "▲" + props.row.price.i + '元(' + k + "%)"
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + '元(' + k + "%)"
            else return props.row.price.i + '元(' + k + "%)"
        } else return '▲0元(0%)'
    }

    const getMeta = () => {
        return <Meta3 setOpenSetting2={setOpenSetting2} title1={""} row={props.row} runDay={props.runDay}></Meta3>
    }


    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.s,
            bid: props.row.b,
        }

        if (localStorage.getItem("chip_lic") != null) {
            param['lic'] = localStorage.getItem("chip_lic")
        }

        param = putInpQueryParam(param)

        fetch("/chip/v1/love/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(
                result => {
                    if (props.fetchData != null) props.fetchData()
                }
            );

    }

    const getTradingChip = () => {
        if (props.row.trading_rate > 80)
            return <Chip size="small" color="error" variant="filled" label={"當沖率 " + props.row.trading_rate + "%"} />
        else if (props.row.trading_rate > 70)
            return <Chip size="small" color="error" variant="outlined" label={"當沖率 " + props.row.trading_rate + "%"} />
        else return null
    }

    return (
        <>
            {openSetting && props.row.company != null ? <LoveSetting name={props.row.company.short_name} sid={props.row.company.stock_no} tid={tid} open={openSetting} close={setOpenSetting}></LoveSetting> : null}
            {openSetting1 ? <MainBroker runDay={props.runDay} name={props.row.company != null ? props.row.company.short_name : ""} stock={props.row.s} id={props.tp} open={openSetting1} close={setOpenSetting1}></MainBroker> : null}
            {openSetting2 ? <MainBroker1 name={props.row.company != null ? props.row.company.short_name : ""} broker_name={props.row.broker_name} broker={props.row.b} stock={props.row.s} open={openSetting2} close={setOpenSetting2}></MainBroker1> : null}

            <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 0.5, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0, borderRadius: '0px' }}>
                <Stack direction={"row"} sx={{}} >
                    <Typography sx={{ p: 1, bgcolor: theme.palette.mode === "light" ? grey[200] : grey[900] }} fontSize={36}>{props.row.i}</Typography>

                    <Divider orientation="vertical" flexItem />
                    <Box sx={{ pl: 0 }} flexGrow={1}>
                        <Stack direction={"row"} sx={{ pl: 0.5 }}>
                            <Typography sx={{ display: "inline", alignSelf: "center", fontSize: 20 }} onClick={e => setOpenSetting2(true)}>{props.row.broker_name}</Typography>
                            <Typography sx={{ display: "inline", ml: 0.5, fontSize: 12, alignSelf: "center", flexGrow: (props.tp === 2 || props.tp === 3) ? 0 : 1 }} >{props.row.b}</Typography>
                            {(props.tp === 2 || props.tp === 3) && <Box sx={{ flexGrow: 1, alignSelf: "center" }}><IconButton size="small" onClick={e => setOpenSetting1(true)}>
                                <KeyboardDoubleArrowRightIcon></KeyboardDoubleArrowRightIcon>
                            </IconButton></Box>}
                            <Box sx={{ alignSelf: "center", m: 1 }}>

                                {props.row.islv ? <Favorite color="error" sx={{ alignSelf: "center" }} onClick={e => clickLove()} /> :
                                    <FavoriteBorderOutlined sx={{ alignSelf: "center" }} onClick={e => clickLove()} />}
                            </Box>
                        </Stack>

                        <Divider sx={{ mt: 0 }} variant="fullWidth" ></Divider>

                        <Stack direction={"row"} onClick={e => setOpenSetting2(true)}>
                            <Box flexGrow={1}>
                                <Typography sx={{ display: "inline", p: 0.5, alignSelf: "center", fontSize: 20 }} >{props.row.company != null ? props.row.company.short_name.substr(0, 5) : ""}</Typography>
                                <Typography sx={{ display: "inline", ml: 0.5, fontSize: 12 }} >{props.row.s}</Typography>
                                <Stack direction='row' spacing={0.5} sx={{ pl: 0.5, alignSelf: 'center' }} onClick={e => setOpen(true)}>
                                    <Typography color={getColor()} variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.price != null ? props.row.price.p : "-"}</Typography>
                                    <Typography color={getColor()} variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                                </Stack>
                            </Box>
                            <Box sx={{ alignSelf: "center", mr: 1 }}>
                                {getTradingChip()}
                            </Box>
                        </Stack>


                    </Box>
                </Stack>



                <Divider sx={{}} />

                <BuySellChart setOpenSetting2={setOpenSetting2} data={props.row.buy_sell} title={"分點買賣超"} />
                <Divider sx={{ mt: 1 }} />
                {getMeta()}
                <Divider sx={{}} ></Divider>

                <Box >
                    <KChart setOpenSetting2={setOpenSetting2} row={props.row} title={"平均成本:" + props.row.avg_cost + "元"} />
                </Box>
            </Stack>
        </>
    );
}
