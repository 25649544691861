const Dollar10000 = [
    {id: "50", value: "50萬元"},
    {id: "100", value: "100萬元"}, 
    {id: "200", value: "200萬元"},
    {id: "300", value: "300萬元"},
    {id: "400", value: "400萬元"},
    {id: "500", value: "500萬元"}, 
    {id: "600", value: "600萬元"},
    {id: "700", value: "700萬元"},
    {id: "800", value: "800萬元"},
    {id: "900", value: "900萬元"},
    {id: "1000", value: "1,000萬元"},
    {id: "1500", value: "1,500萬元"},
    {id: "2000", value: "2,000萬元"},
    {id: "3000", value: "3,000萬元"},
    {id: "5000", value: "5,000萬元"},    
  ];  
  
  export default Dollar10000  