const Stock0_2000 = [
    {id: "0", value: "0股"},
    {id: "10", value: "10股"},    
    {id: "30", value: "30股"},
    {id: "50", value: "50股"},        
    {id: "80", value: "80股"},        
    {id: "100", value: "100股"},
    {id: "150", value: "150股"},  
    {id: "200", value: "200股"},
    {id: "300", value: "300股"},
    {id: "400", value: "400股"},
    {id: "500", value: "500股"},
    {id: "600", value: "600股"},
    {id: "700", value: "700股"},
    {id: "800", value: "800股"},
    {id: "900", value: "900股"},
    {id: "1000", value: "1000股"},
    {id: "2000", value: "2000股"},                                                      
  ];  
  
  export default Stock0_2000 