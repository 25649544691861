const Day1020 = [
    {id: "10", value: "10天"},
    {id: "15", value: "15天"},        
    {id: "20", value: "20天"},
    {id: "25", value: "25天"},
    {id: "30", value: "30天"},
    {id: "40", value: "40天"},     
    {id: "50", value: "50天"},     
    {id: "60", value: "60天"},     
    {id: "70", value: "70天"},     
  ];  
  
  export default Day1020  