const Year10 = [
    {id: "12", value: "1年"},    
    {id: "24", value: "2年"},
    {id: "36", value: "3年"},
    {id: "48", value: "4年"},
    {id: "60", value: "5年"},
    {id: "72", value: "6年"},
    {id: "84", value: "7年"},
    {id: "96", value: "8年"},
    {id: "108", value: "9年"},
    {id: "120", value: "10年"}
  ];  
  
  export default Year10  