const Day_5w = [
    {id: "1", value: "1周"},    
    {id: "2", value: "2周"},
    {id: "3", value: "3周"},
    {id: "4", value: "4周"},  
    {id: "5", value: "5周"}, 
    {id: "6", value: "6周"}, 
    {id: "7", value: "7周"},  
    {id: "8", value: "8周"},  
    {id: "9", value: "9周"},                     
    {id: "10", value: "10周"},
    {id: "15", value: "15周"},
    {id: "20", value: "20周"},
    {id: "25", value: "25周"},
    {id: "30", value: "30周"},
    {id: "35", value: "35周"},
    {id: "40", value: "40周"},
    {id: "45", value: "45周"},
    {id: "50", value: "50周"},
    {id: "55", value: "55周"},    
    {id: "60", value: "60周"},
    {id: "70", value: "70周"},
    {id: "80", value: "80周"},
    {id: "90", value: "90周"},
    {id: "100", value: "100周"},
    {id: "120", value: "120周"},        
  ];  
  
  export default Day_5w