const PL = [
    { id: "0", value: "營收" },
    { id: "1", value: "毛利" },
    { id: "2", value: "營業費用" },
    { id: "3", value: "營業利益" },
    { id: "4", value: "業外" },
    { id: "5", value: "稅前淨利" },
    { id: "6", value: "所得稅" },
    { id: "7", value: "本期淨利" },
    { id: "8", value: "EPS" },
];

export default PL  