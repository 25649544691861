const Day_5m = [
    {id: "1", value: "1月"},    
    {id: "2", value: "2月"},
    {id: "3", value: "3月"},
    {id: "4", value: "4月"},  
    {id: "5", value: "5月"}, 
    {id: "6", value: "6月"}, 
    {id: "7", value: "7月"},  
    {id: "8", value: "8月"},  
    {id: "9", value: "9月"},                     
    {id: "10", value: "10月"},
    {id: "15", value: "15月"},
    {id: "20", value: "20月"},
    {id: "25", value: "25月"},
    {id: "30", value: "30月"},
    {id: "35", value: "35月"},
    {id: "40", value: "40月"},
    {id: "45", value: "45月"},
    {id: "50", value: "50月"},
    {id: "55", value: "55月"},    
    {id: "60", value: "60月"}       
  ];  
  
  export default Day_5m