const Season1 = [
    {id: "1", value: "1季"}, 
    {id: "2", value: "2季"}, 
    {id: "3", value: "3季"}, 
    {id: "4", value: "4季"},
    {id: "5", value: "5季"},
    {id: "6", value: "6季"},    
    {id: "7", value: "7季"}, 
    {id: "8", value: "8季"}, 
    {id: "9", value: "9季"}, 
    {id: "10", value: "10季"},
  ];  
  
  export default Season1  