const Week10 = [
    {id: "1", value: "1週"}, 
    {id: "2", value: "2週"}, 
    {id: "3", value: "3週"},
    {id: "4", value: "4週"},
    {id: "5", value: "5週"},
    {id: "6", value: "6週"},
    {id: "7", value: "7週"},
    {id: "8", value: "8週"},                
    {id: "9", value: "9週"},                
    {id: "10", value: "10週"},                
    {id: "11", value: "11週"},                
    {id: "12", value: "12週"}                 
];  
  
  export default Week10  