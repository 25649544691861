const PercentDot = [
    {id: "0", value: "0%"},
    {id: "1", value: "0.1%"},
    {id: "2", value: "0.2%"},          
    {id: "3", value: "0.3%"},    
    {id: "5", value: "0.5%"},
    {id: "8", value: "0.8%"},    
    {id: "10", value: "1%"},
    {id: "15", value: "1.5%"},         
    {id: "20", value: "2%"}, 
    {id: "25", value: "2.5%"},       
    {id: "30", value: "3%"},
    {id: "35", value: "3.5%"}, 
    {id: "40", value: "4%"},
    {id: "45", value: "4.5%"},    
    {id: "50", value: "5%"},
    {id: "60", value: "6%"},
    {id: "70", value: "7%"},
    {id: "80", value: "8%"},
    {id: "90", value: "9%"},
    {id: "100", value: "10%"},
    {id: "110", value: "11%"}, 
    {id: "120", value: "12%"}, 
    {id: "130", value: "13%"}, 
    {id: "140", value: "14%"}, 
    {id: "150", value: "15%"}, 
    {id: "160", value: "16%"}, 
    {id: "170", value: "17%"}, 
    {id: "180", value: "18%"}, 
    {id: "190", value: "19%"}, 
    {id: "200", value: "20%"},  
    {id: "250", value: "25%"}, 
    {id: "300", value: "30%"}       
  ];  
  
  export default PercentDot  