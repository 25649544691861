const Avg = [
    {id: "4", value: "5日，10日"},    
    {id: "0", value: "5日，20日"},
    {id: "5", value: "5日，10日, 20日"},        
    {id: "1", value: "5日，20日，60日"},
    {id: "2", value: "5日，10日，20日，60日"},
    {id: "3", value: "20日，60日，120日"},
    {id: "6", value: "35日，65日"},
  ];  
  
  export default Avg