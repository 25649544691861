const T20 = [
    {id: "1", value: "1次"},    
    {id: "2", value: "2次"},    
    {id: "3", value: "3次"},       
    {id: "4", value: "4次"},
    {id: "5", value: "5次"},
    {id: "6", value: "6次"},
    {id: "7", value: "7次"},
    {id: "8", value: "8次"},
    {id: "9", value: "9次"},
    {id: "10", value: "10次"}  
  ];  
  
  export default T20  