const Day_51 = [
    {id: "1", value: "1天"},    
    {id: "2", value: "2天"},
    {id: "3", value: "3天"},
    {id: "4", value: "4天"},  
    {id: "5", value: "5天"}, 
    {id: "6", value: "6天"}, 
    {id: "7", value: "7天"},  
    {id: "8", value: "8天"},  
    {id: "9", value: "9天"},                     
    {id: "10", value: "10天"},
    {id: "11", value: "11天"},
    {id: "12", value: "12天"},
    {id: "13", value: "13天"},
    {id: "14", value: "14天"},              
    {id: "15", value: "15天"},
    {id: "16", value: "16天"},
    {id: "17", value: "17天"},
    {id: "18", value: "18天"},
    {id: "19", value: "19天"},                
    {id: "20", value: "20天"},
    {id: "21", value: "21天"},
    {id: "22", value: "22天"},
    {id: "23", value: "23天"},
    {id: "24", value: "24天"},                
    {id: "25", value: "25天"},
    {id: "26", value: "26天"},    
    {id: "27", value: "27天"},
    {id: "28", value: "28天"},
    {id: "29", value: "29天"},
    {id: "30", value: "30天"},
    {id: "35", value: "35天"},
    {id: "40", value: "40天"},
    {id: "45", value: "45天"},
    {id: "50", value: "50天"},
    {id: "55", value: "55天"},    
    {id: "60", value: "60天"},
    {id: "70", value: "70天"},
    {id: "80", value: "80天"},
    {id: "90", value: "90天"},
    {id: "100", value: "100天"},
    {id: "120", value: "120天"},        
  ];  
  
  export default Day_51  