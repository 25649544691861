const Season3Year4 = [
    {id: "3", value: "1季"}, 
    {id: "6", value: "2季"}, 
    {id: "9", value: "3季"}, 
    {id: "12", value: "1年"},    
    {id: "24", value: "2年"},
    {id: "36", value: "3年"},
    {id: "48", value: "4年"}
  ];  
  
  export default Season3Year4  