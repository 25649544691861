const Piece0_50000 = [
    {id: "0", value: "0張"},
    {id: "10", value: "10張"},
    {id: "20", value: "20張"},          
    {id: "30", value: "30張"},
    {id: "40", value: "40張"},          
    {id: "50", value: "50張"},
    {id: "60", value: "60張"},
    {id: "70", value: "70張"},
    {id: "80", value: "80張"},
    {id: "90", value: "90張"},
    {id: "95", value: "95張"},                      
    {id: "100", value: "100張"},
    {id: "300", value: "300張"},
    {id: "400", value: "400張"},  
    {id: "500", value: "500張"},    
    {id: "600", value: "600張"},
    {id: "700", value: "700張"},    
    {id: "800", value: "800張"},    
    {id: "1000", value: "1,000張"},
    {id: "2000", value: "2,000張"},    
    {id: "3000", value: "3,000張"},
    {id: "5000", value: "5,000張"},
    {id: "8000", value: "8,000張"},
    {id: "10000", value: "10,000張"},
    {id: "30000", value: "30,000張"}, 
    {id: "50000", value: "50,000張"}
  ];  
  
  export default Piece0_50000  