const NumberN10N = [
    {value: "200", id: "2000"},    
    {value: "100", id: "1000"},    
    {value: "90", id: "900"},    
    {value: "80", id: "800"},    
    {value: "70", id: "700"},
    {value: "60", id: "600"},
    {value: "50", id: "500"},
    {value: "45", id: "450"},
    {value: "40", id: "400"},
    {value: "35", id: "350"},
    {value: "30", id: "300"},
    {value: "25", id: "250"},
    {value: "20", id: "200"},
    {value: "15", id: "150"},
    {value: "10", id: "100"},
    {value: "9.9", id: "99"},
    {value: "9.8", id: "98"},
    {value: "9.7", id: "97"},
    {value: "9.6", id: "96"},
    {value: "9.5", id: "95"},
    {value: "9.4", id: "94"},    
    {value: "9.3", id: "93"},
    {value: "9.2", id: "92"},
    {value: "9.1", id: "91"},
    {value: "9", id: "90"},
    {value: "8", id: "80"},
    {value: "7", id: "70"},
    {value: "6", id: "60"},
    {value: "5", id: "50"},
    {value: "4", id: "40"},
    {value: "3", id: "30"},
    {value: "2", id: "20"},
    {value: "1", id: "10"},    
    {value: "0", id: "0"},
    {value: "-1", id: "-10"},
    {value: "-2", id: "-20"},
    {value: "-3", id: "-30"},
    {value: "-4", id: "-40"},                
    {value: "-5", id: "-50"},
    {value: "-6", id: "-60"},
    {value: "-7", id: "-70"},
    {value: "-8", id: "-80"},
    {value: "-9", id: "-90"},
    {value: "-9.1", id: "-91"},
    {value: "-9.2", id: "-92"},
    {value: "-9.3", id: "-93"},
    {value: "-9.4", id: "-94"},
    {value: "-9.5", id: "-95"},
    {value: "-9.6", id: "-96"},
    {value: "-9.7", id: "-97"},
    {value: "-9.8", id: "-98"},
    {value: "-9.9", id: "-99"},                       
    {value: "-10", id: "-100"},    
    {value: "-15", id: "-150"},       
    {value: "-20", id: "-200"},
    {value: "-25", id: "-250"},    
    {value: "-30", id: "-300"},
    {value: "-35", id: "-350"},    
    {value: "-40", id: "-400"},
    {value: "-45", id: "-450"},
    {value: "-50", id: "-500"},
    {value: "-55", id: "-550"},    
    {value: "-60", id: "-600"},
    {value: "-65", id: "-650"},    
    {value: "-70", id: "-700"},
    {value: "-75", id: "-750"},
    {value: "-80", id: "-800"},  
    {value: "-85", id: "-850"},    
    {value: "-90", id: "-900"},
    {value: "-95", id: "-950"},
    {value: "-96", id: "-960"},
    {value: "-97", id: "-970"},
    {value: "-98", id: "-980"},
    {value: "-99", id: "-990"},                    
    {value: "-100", id: "-1000"},
  ];  
  
  export default NumberN10N