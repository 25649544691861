const Percent5_5_100N = [
    { id: "-1", value: "-1%" },
    { id: "-2", value: "-2%" },
    { id: "-3", value: "-3%" },
    { id: "-4", value: "-4%" },
    { id: "-5", value: "-5%" },
    { id: "-6", value: "-6%" },
    { id: "-7", value: "-7%" },
    { id: "-8", value: "-8%" },
    { id: "-9", value: "-9%" },
    { id: "-10", value: "-10%" },
    { id: "-12", value: "-12%" },
    { id: "-13", value: "-13%" },
    { id: "-14", value: "-14%" },
    { id: "-15", value: "-15%" },
    { id: "-20", value: "-20%" },
    { id: "-25", value: "-25%" },
    { id: "-30", value: "-30%" },
    { id: "-35", value: "-35%" },
    { id: "-40", value: "-40%" },
    { id: "-45", value: "-45%" },
    { id: "-50", value: "-50%" },
    { id: "-55", value: "-55%" },
    { id: "-60", value: "-60%" },
    { id: "-65", value: "-65%" },
    { id: "-70", value: "-70%" },
    { id: "-75", value: "-75%" },
    { id: "-80", value: "-80%" },
    { id: "-85", value: "-85%" },
    { id: "-90", value: "-90%" },
    { id: "-95", value: "-95%" },
    { id: "-100", value: "-100%" },
    { id: "-105", value: "-105%" },
    { id: "-110", value: "-110%" },
    { id: "-115", value: "-115%" },
    { id: "-120", value: "-120%" },
    { id: "-125", value: "-125%" },
    { id: "-130", value: "-130%" },
    { id: "-135", value: "-135%" },
    { id: "-140", value: "-140%" },
    { id: "-145", value: "-145%" },
    { id: "-150", value: "-150%" },
    { id: "-200", value: "-200%" },
    { id: "-300", value: "-300%" },
    { id: "-400", value: "-400%" },
    { id: "-500", value: "-500%" },
    { id: "0", value: "0%" },
    { id: "1", value: "1%" },
    { id: "2", value: "2%" },
    { id: "3", value: "3%" },
    { id: "4", value: "4%" },
    { id: "5", value: "5%" },
    { id: "6", value: "6%" },
    { id: "7", value: "7%" },
    { id: "8", value: "8%" },
    { id: "9", value: "9%" },
    { id: "10", value: "10%" },
    { id: "12", value: "12%" },
    { id: "13", value: "13%" },
    { id: "14", value: "14%" },
    { id: "15", value: "15%" },
    { id: "20", value: "20%" },
    { id: "25", value: "25%" },
    { id: "30", value: "30%" },
    { id: "35", value: "35%" },
    { id: "40", value: "40%" },
    { id: "45", value: "45%" },
    { id: "50", value: "50%" },
    { id: "55", value: "55%" },
    { id: "60", value: "60%" },
    { id: "65", value: "65%" },
    { id: "70", value: "70%" },
    { id: "75", value: "75%" },
    { id: "80", value: "80%" },
    { id: "85", value: "85%" },
    { id: "90", value: "90%" },
    { id: "95", value: "95%" },
    { id: "100", value: "100%" },
    { id: "105", value: "105%" },
    { id: "110", value: "110%" },
    { id: "115", value: "115%" },
    { id: "120", value: "120%" },
    { id: "125", value: "125%" },
    { id: "130", value: "130%" },
    { id: "135", value: "135%" },
    { id: "140", value: "140%" },
    { id: "145", value: "145%" },
    { id: "150", value: "150%" },
    { id: "200", value: "200%" },
    { id: "300", value: "300%" },
    { id: "400", value: "400%" },
    { id: "500", value: "500%" },
];

export default Percent5_5_100N  