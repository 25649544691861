const Percent100_0_1000 = [
    {id: "0", value: "0%"}, 
    {id: "50", value: "50%"},   
    {id: "100", value: "100%"},
    {id: "150", value: "150%"},             
    {id: "200", value: "200%"},
    {id: "250", value: "250%"},      
    {id: "300", value: "300%"},
    {id: "350", value: "350%"},     
    {id: "400", value: "400%"},
    {id: "450", value: "450%"},     
    {id: "500", value: "500%"},
    {id: "550", value: "550%"},     
    {id: "600", value: "600%"},
    {id: "650", value: "650%"},     
    {id: "700", value: "700%"},
    {id: "750", value: "750%"},     
    {id: "800", value: "800%"},
    {id: "850", value: "850%"}, 
    {id: "900", value: "900%"},
    {id: "950", value: "950%"}, 
    {id: "1000", value: "1000%"}
  ];  
  
  export default Percent100_0_1000  