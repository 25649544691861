const Percent5_5_301 = [
    { id: "0", value: "0%" },
    { id: "1", value: "1%" },
    { id: "2", value: "2%" },
    { id: "3", value: "3%" },
    { id: "4", value: "4%" },
    { id: "5", value: "5%" },
    { id: "6", value: "6%" },
    { id: "7", value: "7%" },
    { id: "8", value: "8%" },
    { id: "9", value: "9%" },
    { id: "10", value: "10%" },
    { id: "11", value: "11%" },
    { id: "12", value: "12%" },
    { id: "13", value: "13%" },
    { id: "14", value: "14%" },
    { id: "15", value: "15%" },
    { id: "16", value: "16%" },
    { id: "17", value: "17%" },
    { id: "18", value: "18%" },
    { id: "19", value: "19%" },
    { id: "20", value: "20%" },
    { id: "25", value: "25%" },
    { id: "30", value: "30%" },
    { id: "35", value: "35%" },
    { id: "40", value: "40%" },
    { id: "45", value: "45%" },
    { id: "50", value: "50%" },
    { id: "55", value: "55%" },
    { id: "60", value: "60%" },
    { id: "65", value: "65%" },
    { id: "70", value: "70%" },
    { id: "75", value: "75%" },
    { id: "80", value: "80%" },
    { id: "85", value: "85%" },
    { id: "90", value: "90%" },
    { id: "95", value: "95%" },
    { id: "100", value: "100%" },
    { id: "110", value: "110%" },
    { id: "120", value: "120%" },
    { id: "130", value: "130%" },
    { id: "140", value: "140%" },
    { id: "150", value: "150%" },
    { id: "160", value: "160%" },
    { id: "170", value: "170%" },
    { id: "180", value: "180%" },
    { id: "190", value: "190%" },
    { id: "200", value: "200%" },
    { id: "250", value: "250%" },
    { id: "300", value: "300%" },
    { id: "500", value: "500%" },
    { id: "700", value: "700%" },
    { id: "1000", value: "1000%" },
    { id: "2000", value: "2000%" },
];

export default Percent5_5_301