const MoneyMB = [
  { id: "0", value: "0元" },
  { id: "1000", value: "1千萬元" },
  { id: "3000", value: "3千萬元" },
  { id: "5000", value: "5千萬元" },
  { id: "7000", value: "7千萬元" },
  { id: "10000", value: "1億元" },
  { id: "30000", value: "3億元" },
  { id: "50000", value: "5億元" },
  { id: "100000", value: "10億元" },
  { id: "300000", value: "30億元" },
  { id: "500000", value: "50億元" },
  { id: "700000", value: "70億元" },
  { id: "1000000", value: "100億元" },
  { id: "2000000", value: "200億元" },
  { id: "5000000", value: "500億元" },
  { id: "10000000", value: "1000億元" },
  { id: "30000000", value: "3000億元" },
  { id: "50000000", value: "5000億元" },
  { id: "100000000", value: "1兆元" },
  { id: "1000000000", value: "10兆元" }, 
  { id: "2000000000", value: "20兆元" },  
];

export default MoneyMB