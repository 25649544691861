const NumberSmall = [   
    {id: "0", value: "0%"},
    {id: "1", value: "0.1%"},
    {id: "2", value: "0.2%"},        
    {id: "3", value: "0.3%"},
    {id: "4", value: "0.4%"},    
    {id: "5", value: "0.5%"},
    {id: "6", value: "0.6%"},    
    {id: "7", value: "0.7%"}, 
    {id: "8", value: "0.8%"},
    {id: "9", value: "0.9%"},                
    {id: "10", value: "1.0%"},
    {id: "13", value: "1.3%"},
    {id: "15", value: "1.5%"},
    {id: "17", value: "1.7%"},
    {id: "20", value: "2.0%"},
    {id: "25", value: "2.5%"},
    {id: "30", value: "3.0%"},
    {id: "35", value: "3.5%"},
    {id: "40", value: "4.0%"},
    {id: "45", value: "4.5%"},        
    {id: "50", value: "5.0%"},
    {id: "100", value: "10%"},           
  ];  
  
  export default NumberSmall  