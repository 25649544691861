const Percent10_100_0 = [
    {id: "100", value: "100%"},
    {id: "90", value: "90%"},    
    {id: "80", value: "80%"},    
    {id: "70", value: "70%"},       
    {id: "60", value: "60%"},
    {id: "50", value: "50%"},
    {id: "40", value: "40%"},
    {id: "30", value: "30%"},
    {id: "20", value: "20%"},
    {id: "10", value: "10%"},
    {id: "0", value: "0%"},    
  ];  
  
  export default Percent10_100_0  