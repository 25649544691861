import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function EMASetting(props) {
    const yref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "") {
            localStorage.setItem('ema1', yref.current.value);
            
            if(yref.current.value === 'EMA') localStorage.setItem('ema', 1);
            else localStorage.setItem('ema', 0)
        }

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('ema1') === null ? 'SMA' : localStorage.getItem('ema1')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">均線參數</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="預設:SMA，可輸入EMA"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}