const Tp = [
    {id: "1", value: "化學工業"}, 
    {id: "2", value: "水泥工業"}, 
    {id: "3", value: "半導體業"}, 
    {id: "4", value: "生技醫療業"},  
    {id: "5", value: "光電業"},      
    {id: "6", value: "存託憑證"},
    {id: "7", value: "汽車工業"},
    {id: "8", value: "金融保險業"},
    {id: "9", value: "油電燃氣業"},
    {id: "10", value: "建材營造"},
    {id: "11", value: "玻璃陶瓷"},
    {id: "12", value: "食品工業"},
    {id: "13", value: "紡織纖維"},
    {id: "14", value: "航運業"},    
    {id: "15", value: "通信網路業"},
    {id: "16", value: "造紙工業"},    
    {id: "17", value: "貿易百貨"},
    {id: "18", value: "塑膠工業"},
    {id: "19", value: "資訊服務業"},
    {id: "20", value: "電子通路業"},
    {id: "21", value: "電子零組件業"}, 
    {id: "22", value: "電腦及週邊設備業"},   
    {id: "23", value: "電器電纜"},
    {id: "24", value: "電機機械"},    
    {id: "25", value: "橡膠工業"},
    {id: "26", value: "鋼鐵工業"},    
    {id: "27", value: "觀光事業"},
    {id: "28", value: "文化創意業"},
    {id: "29", value: "農業科技"},
    {id: "30", value: "電子商務"},
    {id: "31", value: "管理股票"}, 
    {id: "32", value: "其他電子業"}, 
    {id: "33", value: "其他"}, 
  ];  
  
  export default Tp  