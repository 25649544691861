import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SearchBroker(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const oninput = (event, value, reason) => {
    setInputValue(value)
    console.log("setInputValue:" + value)
    if ((isNumeric(value) && value.length > 1) || (!isNumeric(value) && value.length > 0)) {
      fetch("/chip/v1/info/searchBroker?key=" + value)
        .then(res => res.json())
        .then(
          result => {
            setOptions(result);
          });
    }
  }

  const onclose = (event, reason) => {
    setOpen(false);
  }

  const onchange = (event, value) => {
    if (value != null) {
      if (props.setSID != null) {
        props.setSID(value.value)
        
        if(props.bidOption != null)
        props.bidOption(JSON.stringify(value))

        setValue(value)
      }
      //   props.menuChange(value.value)
    } else {
      if (props.setSID != null) props.setSID(null)
    }
  }

  useEffect(() => {
    if(props.bidValue != null) {
      setValue(JSON.parse(props.bidValue))
    }
  }, []);

  return (

    <Autocomplete
      value={value}
      inputValue={inputValue}
      id="stock input"
      open={open}
      onInputChange={oninput}
      onOpen={() => {
        setOpen(true);
      }}
      freeSolo
      autoHighlight
      onChange={onchange}
      onClose={onclose}
      // getOptionSelected={(option, value) => option.name === value.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={option => option.label}
      options={options}
      noOptionsText=""
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="分點"
          variant="filled"
          InputProps={{
            ...params.InputProps,
          }}

        />
      )}


    />
  );
}

