import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import TextField from '@mui/material/TextField';
import { putInpQueryParam } from '../util/ID'
import { getId } from '../util/ID'

export default function NewRecSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [nowRows, setNowRows] = React.useState([])

    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if (name != null && name != "") {
            // if(nowRows != null)  localStorage.setItem("chip_search_rules", JSON.stringify(nowRows))
            let param = {
                sn: getId(),
                rules: nowRows,
                name: name,
                inp: {}
            }

            if (localStorage.getItem("chip_lic") != null) {
                param['lic'] = localStorage.getItem("chip_lic")
            }

            param = putInpQueryParam(param)

            fetch("/chip/v1/stragic/new", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(
                    result => {
                        setOpen(false);
                        if (props.close != null) props.close(false)

                        if (props.refresh != null) {
                            props.refresh()
                        }
                    }
                );



        }
    };



    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"新增策略"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <TextField
                        sx={{ mt: 1, mb: 1 }}
                        fullWidth
                        id="outlined-controlled"
                        label="策略名稱"
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />

                    <AllStrageRules updateRule={updateRule} rows={nowRows} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button onClick={handleClose}>關閉</Button>
                    {/* isabled={localStorage.getItem("noad") != "1"} */}
                    {localStorage.getItem("noad") === "1" ? <Button onClick={handleOKClose} autoFocus>
                        新增
                    </Button> : <Button disabled autoFocus>
                        新增 (VIP專屬)
                    </Button>

                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
