const STp = [
    {id: "0", value: "所有股票"},     
    {id: "1", value: "上市股票"},    
    {id: "2", value: "上櫃股票"},
    {id: "3", value: "所有ETF"},      
    {id: "4", value: "上市ETF"},    
    {id: "5", value: "上櫃ETF"},
    {id: "6", value: "所有自選股"},     
  ];  
  
  export default STp  