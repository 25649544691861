const ZDay = [
    {id: "1", value: "1日"},
    {id: "2", value: "2日"},       
    {id: "3", value: "3日"},
    {id: "4", value: "4日"},        
    {id: "5", value: "5日"},
    {id: "6", value: "6日"},
    {id: "7", value: "7日"},
    {id: "8", value: "8日"},
    {id: "9", value: "9日"},              
    {id: "10", value: "10日"},
    {id: "11", value: "11日"},    
    {id: "12", value: "12日"},
    {id: "13", value: "13日"},
    {id: "14", value: "14日"},        
    {id: "15", value: "15日"},
    {id: "16", value: "16日"},
    {id: "17", value: "17日"},
    {id: "18", value: "18日"},
    {id: "19", value: "19日"},                
    {id: "20", value: "20日"},    
  ];  
  
  export default ZDay  