import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

export default function RecSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState(localStorage.getItem('chip_search_sort') != null ? localStorage.getItem('chip_search_sort') : '0');
    const [money, setMoney] = React.useState(localStorage.getItem('chip_search_money') != null ? localStorage.getItem('chip_search_money') : '10000');


    const [stp, setSTP] = React.useState(localStorage.getItem('chip_search_stp') != null ? localStorage.getItem('chip_search_stp') : '0');
    const [nowRows, setNowRows] = React.useState(localStorage.getItem('chip_search_rules') != null ? JSON.parse(localStorage.getItem('chip_search_rules')) : [])


    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        if(sort != null && sort != "")  localStorage.setItem("chip_search_sort", sort)
        if(money != null && money != "") localStorage.setItem("chip_search_money", money)
        if(nowRows != null)  localStorage.setItem("chip_search_rules", JSON.stringify(nowRows))
        if(stp != null && stp != "") localStorage.setItem("chip_search_stp", stp)

        setOpen(false);
        if (props.close != null) props.close(false)

        if (props.refresh != null) {
            props.refresh()
        }
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"更多設定"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">排序方式</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sort}
                                label="排序方式"
                                onChange={e => setSort(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'年化報酬率'}</MenuItem>
                                <MenuItem value={'1'}>{'資本利得報酬'}</MenuItem>
                                <MenuItem value={'2'}>{'股利報酬'}</MenuItem>
                            </Select>
                        </FormControl>
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-stp">類型</InputLabel>
                            <Select
                                labelId="demo-simple-select-stp"
                                id="demo-simple-stp"
                                value={stp}
                                label="類型"
                                onChange={e => setSTP(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部'}</MenuItem>
                                <MenuItem value={'1'}>{'僅股票'}</MenuItem>
                                <MenuItem value={'2'}>{'僅ETF'}</MenuItem>
                            </Select>
                        </FormControl> */}


                        <TextField
                            fullWidth
                            id="outlined-controlled"
                            label="定期金額"
                            value={money}
                            onChange={(event) => {
                                setMoney(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}
                        />
                    </Stack>

                    <Divider sx={{ m: 2 }}>
                        <Chip label="股票設定" />
                    </Divider>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button onClick={handleClose}>取消</Button>
                    {/* isabled={localStorage.getItem("noad") != "1"} */}
                    {localStorage.getItem("noad") === "1"?<Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>:<Button disabled autoFocus>
                        確定 (VIP專屬)
                    </Button>

                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
