import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';

import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import AlertD from '../util/AlertD';
import { Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import SearchCompany from './SearchCompany'
import SearchBroker from './SearchBroker';
import Grid from '@mui/material/Unstable_Grid2';
import { getId } from '../util/ID'


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [openSetting, setOpenSetting] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const [regTp, setRegTp] = React.useState(0);
    const [runYear, setRunYear] = React.useState(1);
    const [sid, setSID] = React.useState(null);
    const [bid, setBID] = React.useState(null);
    const [stragic, setStragic] = React.useState("");
    const [stragicItems, setStragicItems] = React.useState([]);

    const tpChange = (event) => {
        if ((event.target.value === 4 || event.target.value === 5) && !hasLic()) {
            setAlertMsg("此為VIP功能")
            setShowAlert(true)
        } else setRegTp(event.target.value);
        // if (hasLic())

        // else setRegTp(0)
    };

    const handleYearChange = (event) => {
        setRunYear(event.target.value)
    };


    useEffect(() => {
        if (props.refresh != null) props.refresh(regTp, runYear, sid, bid)
    }, [regTp, runYear, sid, bid]);

    const hasLic = () => {
        return localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1"
    }

    const handleStragicChange = (event) => {
        if (localStorage.getItem("noad") === "1") {
            setStragic(event.target.value)

            if (event.target.value === "" && props.updateRules != null) props.updateRules([])
            else {
                const data = stragicItems.filter(item => item.id === event.target.value)
                if (data.length > 0 && props.updateRules != null) {
                    props.updateRules(data[0].rules)
                } else {
                    props.updateRules([])
                }
            }
        } else setStragic("")
    };

    useEffect(() => {
        getStragic()
    }, []);

    const getStragic = () => {
        let param = {
            sn: getId(),
        }

        if (localStorage.getItem("chip_lic") != null) {
            param['lic'] = localStorage.getItem("chip_lic")
        }

        fetch("/chip/v1/stragic/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setStragicItems(result.l)
                    }
                }
            );
    }

    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', pt: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <RecSetting refresh={props.fetchData} open={openSetting} close={setOpenSetting}></RecSetting> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Grid container spacing={0.2} sx={{ width: "100%" }} columns={17}>
                <Grid xs={4}>
                    <SearchCompany setSID={setSID} />
                </Grid>
                <Grid xs={4}>
                    <SearchBroker setSID={setBID} />
                </Grid>
                <Grid xs={3} >
                    <FormControl variant="filled" fullWidth hiddenLabel >
                        <Select
                            labelId="tpLabel"
                            id="tp"
                            value={regTp}
                            label="tpLabel"
                            onChange={tpChange}
                        >
                            <MenuItem value={0}>買賣超大到小</MenuItem>
                            <MenuItem value={1}>買賣超金額大到小</MenuItem>
                            <MenuItem value={4}>{localStorage.getItem("noad") === "1" ? "當沖獲利大到小" : "當沖獲利大到小（VIP）"}</MenuItem>
                            <Divider />
                            <MenuItem value={2}>買賣超小到大</MenuItem>
                            <MenuItem value={3}>買賣超金額小到大</MenuItem>
                            <MenuItem value={5}>{localStorage.getItem("noad") === "1" ? "當沖虧損大到小" : "當沖虧損大到小（VIP）"}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={2}>
                    <FormControl variant="filled" fullWidth hiddenLabel >
                        <Select
                            labelId="yearLabel"
                            id="year"
                            value={runYear}
                            label="yearLabel"
                            onChange={handleYearChange}
                        >
                            <MenuItem value={1}>1日</MenuItem>
                            <MenuItem value={5}>5日</MenuItem>
                            <MenuItem value={20}>20日</MenuItem>
                            <MenuItem value={40}>40日</MenuItem>
                            <MenuItem value={60}>60日</MenuItem>
                            <MenuItem value={120}>120日</MenuItem>
                            <MenuItem value={240}>240日</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={4}>
                    <FormControl variant="filled" fullWidth hiddenLabel >
                        <Select
                            labelId="stragicLabel"
                            id="stragic"
                            value={stragic}
                            label="stragicLabel"
                            onChange={handleStragicChange}
                        >
                            <MenuItem key={""} value={""}>選擇策略</MenuItem>
                            {stragicItems.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </Box>
    );
}
