const Day_20 = [
    {id: "1", value: "1天"},    
    {id: "2", value: "2天"},
    {id: "3", value: "3天"},
    {id: "4", value: "4天"},
    {id: "5", value: "5天"},
    {id: "6", value: "6天"},
    {id: "7", value: "7天"},
    {id: "8", value: "8天"},
    {id: "9", value: "9天"},
    {id: "10", value: "10天"},
    {id: "20", value: "20天"}
  ];  
  
  export default Day_20  