import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import { Height } from '../util/ScreenHeight'
// import ErrorM from '../util/ErrorM'
// import AskDialog from '../util/AskDialog';
// import Photo from '@mui/icons-material/Photo'
// import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MoreVert from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import RecSetting from './RecSetting';

import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import AlertD from '../util/AlertD';
import { Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import ColorModeContext from '../util/ColorMode'
import NewRecSetting from './NewRecSetting';
import { getId } from '../util/ID'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0
        },
    }

    //  '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //     borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //         }`,
    // },
}));


export default function RecSelector(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);

    const theme = useTheme();
    const classes = useStyles();

    const [regTp, setRegTp] = React.useState(0);
    const [runYear, setRunYear] = React.useState(1);
    const [stp, setSTP] = React.useState(localStorage.getItem('chip_search_stp') != null ? localStorage.getItem('chip_search_stp') : '0');
    const [stragic, setStragic] = React.useState("");
    const [stragicItems, setStragicItems] = React.useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const colorMode = React.useContext(ColorModeContext);
    const [value, setValue] = React.useState(0);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setValue(newValue);

        if (newValue === 1) setOpenSetting1(true)
        else if (newValue === 2) {
            delStragic()
        }
    };

    const handleChange = (event) => {
        // if (hasLic())
        setRegTp(event.target.value);
        // else setRegTp(0)
    };

    const handleYearChange = (event) => {
        if (localStorage.getItem("noad") === "1")
            setRunYear(event.target.value)
        else setRunYear(1)
    };

    const handleStragicChange = (event) => {
        if (localStorage.getItem("noad") === "1") {
            setStragic(event.target.value)

            if (event.target.value === "" && props.updateRules != null) props.updateRules([])
            else {
                const data = stragicItems.filter(item => item.id === event.target.value)
                if (data.length > 0 && props.updateRules != null) {
                    props.updateRules(data[0].rules)
                } else {
                    props.updateRules([])
                }
            }
        } else setStragic("")
    };


    useEffect(() => {
        if (props.refresh != null) props.refresh(regTp, runYear, stp)
    }, [regTp, runYear, stp]);

    const hasLic = () => {
        return localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1"
    }

    useEffect(() => {
        getStragic()
    }, []);

    useEffect(() => {
        setStragic("")
    }, [stragicItems]);

    const getStragic = () => {
        let param = {
            sn: getId(),
        }

        if (localStorage.getItem("chip_lic") != null) {
            param['lic'] = localStorage.getItem("chip_lic")
        }

        fetch("/chip/v1/stragic/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setStragicItems(result.l)
                    }
                }
            );
    }


    const delStragic = () => {
        if (stragic != "") {
            let param = {
                sn: getId(),
                id: stragic
            }

            if (localStorage.getItem("chip_lic") != null) {
                param['lic'] = localStorage.getItem("chip_lic")
            }

            fetch("/chip/v1/stragic/del", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(
                    result => {
                        getStragic()
                    }
                );
        }
    }

    return (
        <Box sx={{ width: '100%', textAlign: 'left', display: 'inline-flex', p: 0, bgcolor: theme.palette.mode === "light" ? "secondary.light" : null }} >
            {openSetting ? <RecSetting refresh={props.fetchData} open={openSetting} close={setOpenSetting}></RecSetting> : null}
            {openSetting1 ? <NewRecSetting refresh={getStragic} open={openSetting1} close={setOpenSetting1}></NewRecSetting> : null}

            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}

            <Stack direction={"row"} spacing={0.2} sx={{ width: "100%", height: Height.subheader - 3, maxHeight: Height.subheader }}>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="tpLabel"
                        id="tp"
                        value={regTp}
                        label="tpLabel"
                        onChange={handleChange}
                    >
                        <Divider>多方</Divider>
                        <MenuItem value={0}>買超成交比</MenuItem>
                        <MenuItem value={2}>買超主力金額</MenuItem>
                        <MenuItem value={4}>買超天數</MenuItem>
                        <MenuItem value={6}>買超成長比</MenuItem>
                        <MenuItem value={8}>庫存盈利</MenuItem>
                        <MenuItem value={10}>當沖賺錢</MenuItem>
                        <Divider>空方</Divider>
                        <MenuItem value={1}>賣超成交比</MenuItem>
                        <MenuItem value={3}>賣超主力金額</MenuItem>
                        <MenuItem value={5}>賣超天數</MenuItem>
                        <MenuItem value={7}>賣超成長比</MenuItem>
                        <MenuItem value={9}>庫存虧損</MenuItem>
                        <MenuItem value={11}>當沖虧損</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="yearLabel"
                        id="year"
                        value={runYear}
                        label="yearLabel"
                        onChange={handleYearChange}
                    >
                        <MenuItem value={1}>1日</MenuItem>
                        {<MenuItem value={5}>{localStorage.getItem("noad") === "1" ? "5日" : "5日（VIP）"}</MenuItem>}
                        {<MenuItem value={20}>{localStorage.getItem("noad") === "1" ? "20日" : "20日（VIP）"}</MenuItem>}
                        {<MenuItem value={40}>{localStorage.getItem("noad") === "1" ? "40日" : "40日（VIP）"}</MenuItem>}
                        {<MenuItem value={60}>{localStorage.getItem("noad") === "1" ? "60日" : "60日（VIP）"}</MenuItem>}
                        {<MenuItem value={120}>{localStorage.getItem("noad") === "1" ? "120日" : "120日（VIP）"}</MenuItem>}
                        {<MenuItem value={240}>{localStorage.getItem("noad") === "1" ? "240日" : "240日（VIP）"}</MenuItem>}
                    </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth hiddenLabel >
                    <Select
                        labelId="stragicLabel"
                        id="stragic"
                        value={stragic}
                        label="stragicLabel"
                        onChange={handleStragicChange}
                    >
                        <MenuItem key={""} value={""}>選擇策略</MenuItem>
                        {stragicItems.map(row => <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>)}
                    </Select>
                </FormControl>


                <IconButton sx={{}} onClick={e => handleMenuClick(e)}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => handleMenuChange(1)}>新增策略</MenuItem>
                    <MenuItem onClick={e => handleMenuChange(2)}>刪除策略</MenuItem>
                </Menu>
            </Stack>
            {/* {props.enableSetting && <Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px', mr: 0.2}} size="small" color="secondary" disableElevation onClick={e => clear()}>清除</Button>} */}
            {/* {<Button variant='contained' sx={{ height: Height.subheader - 3, maxHeight: Height.subheader, whiteSpace: 'nowrap', borderRadius: '0px' }} size="small" color="error" disableElevation onClick={e => setOpenSetting(true)}>更多</Button>} */}
        </Box>
    );
}
