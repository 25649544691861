const MA3 = [
    {id: "3", value: "3日"},    
    {id: "5", value: "5日"},
    {id: "7", value: "7日"}, 
    {id: "9", value: "9日"},          
    {id: "10", value: "10日"},
    {id: "13", value: "13日"},            
    {id: "20", value: "20日"},
    {id: "30", value: "30日"},    
    {id: "40", value: "40日"},    
    {id: "50", value: "50日"},
    {id: "52", value: "52日"},
    {id: "60", value: "60日"},
    {id: "100", value: "100日"},    
    {id: "120", value: "120日"},
    {id: "144", value: "144日"},
    {id: "200", value: "200日"},
    {id: "240", value: "240日"},    

  ];  
  
  export default MA3