const Ba1 = [
    {id: "10", value: "10倍"},    
    {id: "30", value: "30倍"}, 
    {id: "50", value: "50倍"}, 
    {id: "70", value: "70倍"}, 
    {id: "100", value: "100倍"},  
    {id: "300", value: "300倍"},                     
    {id: "500", value: "500倍"},
    {id: "700", value: "700倍"},
    {id: "1000", value: "1000倍"},
  ];  
  
  export default Ba1  