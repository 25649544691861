const Number1000 = [
    {id: "0", value: "0"},
    {id: "50", value: "50"},    
    {id: "100", value: "100"},
    {id: "200", value: "200"},
    {id: "300", value: "300"},
    {id: "400", value: "400"},     
    {id: "500", value: "500"},
    {id: "600", value: "600"},
    {id: "700", value: "700"},
    {id: "800", value: "800"},
    {id: "900", value: "900"},
    {id: "1000", value: "1000"},               
    {id: "1100", value: "1100"},               
    {id: "1200", value: "1200"},               
    {id: "1300", value: "1300"},               
    {id: "1400", value: "1400"},               
    {id: "1500", value: "1500"},               

];  
  
  export default Number1000  