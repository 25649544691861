const BS1 = [
    { id: "0", value: "資產" },
    { id: "1", value: "流動資產" },
    { id: "2", value: "現金當約現金" },
    { id: "3", value: "存貨" },
    { id: "4", value: "應收帳款" },
    { id: "5", value: "非流動資產" },
    { id: "6", value: "無形資產" },
    { id: "7", value: "不動產" },
    { id: "8", value: "負債" },
    { id: "9", value: "流動負債" },
    { id: "10", value: "非流動負債" },
    { id: "11", value: "股東權益" },
];


export default BS1  