const Golden = [
    {id: "0", value: "0"},    
    {id: "191", value: "0.191"},    
    {id: "382", value: "0.382"},   
    {id: "500", value: "0.5"},  
    {id: "618", value: "0.618"},      
    {id: "809", value: "0.809"}, 
    {id: "1000", value: "1"},     
  ];  
  


  export default Golden