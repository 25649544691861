const EBSMenu = [
    {id: "0", value: "散戶賣 -> 大戶買"},    
    {id: "1", value: "大戶賣 -> 散戶買"},
    {id: "2", value: "散戶跟大戶都買"},
    {id: "3", value: "散戶跟大戶都賣"},
    {id: "4", value: "散戶跟大戶都中立"},
    {id: "5", value: "大戶買超"},
    {id: "6", value: "大戶賣超"},
    {id: "7", value: "散戶賣超"},
    {id: "8", value: "散戶買超"}     
  ];  
  
  export default EBSMenu