import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function MACDSetting(props) {
    //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const hrref = React.createRef();
    const drref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('e12', parseInt(yref.current.value));

        if (hrref.current.value != null && hrref.current.value != "")
            localStorage.setItem('e26', parseFloat(hrref.current.value));

        if (drref.current.value != null && drref.current.value != "")
            localStorage.setItem('e9', parseFloat(drref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('e12') === null ? 12 : localStorage.getItem('e12')
    }

    function hR() {
        return localStorage.getItem('e26') === null ? 26 : localStorage.getItem('e26')
    }

    function hkdk() {
        return localStorage.getItem('e9') === null ? 9 : localStorage.getItem('e9')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">MACD參數</DialogTitle>

            <DialogContent>
                <br /><br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="短天期EMA(預設:12)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="hr"
                    label="長天期EMA(預設:26)"
                    type="text"
                    fullWidth
                    defaultValue={hR()}
                    inputRef={hrref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="dense"
                    id="kdk"
                    label="MACD天期(預設:9)"
                    type="text"
                    fullWidth
                    defaultValue={hkdk()}
                    inputRef={drref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}
