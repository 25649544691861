const SeasonAll = [
    {id: "3", value: "1季"}, 
    {id: "6", value: "2季"}, 
    {id: "9", value: "3季"}, 
    {id: "12", value: "4季"},
    {id: "15", value: "5季"},
    {id: "18", value: "6季"},    
    {id: "21", value: "7季"}, 
    {id: "24", value: "8季"}, 
    {id: "27", value: "9季"}, 
    {id: "30", value: "10季"},
  ];  
  
  export default SeasonAll  