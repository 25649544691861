const Month24 = [
    {id: "1", value: "1個月"},    
    {id: "2", value: "2個月"},
    {id: "3", value: "3個月"},
    {id: "4", value: "4個月"}, 
    {id: "5", value: "5個月"},
    {id: "6", value: "6個月"},
    {id: "7", value: "7個月"},
    {id: "8", value: "8個月"},
    {id: "9", value: "9個月"},
    {id: "10", value: "10個月"},
    {id: "11", value: "11個月"},
    {id: "12", value: "12個月"}, 
    {id: "13", value: "13個月"},  
    {id: "14", value: "14個月"},  
    {id: "15", value: "15個月"},  
    {id: "16", value: "16個月"},  
    {id: "17", value: "17個月"},  
    {id: "18", value: "18個月"},  
    {id: "19", value: "19個月"},  
    {id: "20", value: "20個月"},  
    {id: "21", value: "21個月"},  
    {id: "22", value: "22個月"},  
    {id: "23", value: "23個月"},  
    {id: "24", value: "24個月"},  
  ];  
  
  export default Month24