const Number1110 = [
    {id: "-10", value: "-1.0"},
    {id: "-9", value: "-0.9"},    
    {id: "-8", value: "-0.8"},        
    {id: "-7", value: "-0.7"},
    {id: "-6", value: "-0.6"},         
    {id: "-5", value: "-0.5"},
    {id: "-4", value: "-0.4"},        
    {id: "-3", value: "-0.3"},    
    {id: "-2", value: "-0.2"},
    {id: "-1", value: "-0.1"},
    {id: "0", value: "0"},
    {id: "1", value: "0.1"},
    {id: "2", value: "0.2"},        
    {id: "3", value: "0.3"},
    {id: "4", value: "0.4"},    
    {id: "5", value: "0.5"},
    {id: "6", value: "0.6"},
    {id: "7", value: "0.7"},
    {id: "8", value: "0.8"},
    {id: "9", value: "0.9"},                 
    {id: "10", value: "1.0"},
  ];  
  
  export default Number1110  