import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';


echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function KChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);

    function genoption1() {
        let k = {
            animation: false,
            title: [
                {
                    left: 'left',
                    text: props.title,
                    textStyle: {
                        fontSize: 12
                    },
                    // top: 0
                }],

            grid: [{
                top: 28,
                left: 5,
                right: 5,
                // height: 100,
                bottom: 0,
                containLabel: true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: props.row.prices.map(row => row.d),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }],
            series: [
                {
                    name: '價格',
                    type: 'line',
                    data: props.row.prices.map(row => row.p),
                    smooth: true,
                    showSymbol: false,
                }
            ]
        };
        return k;
    }

    useEffect(() => {
        // fetchData()
        if (props.row.prices != null) setOption(genoption1)
    }, []);

    const clickDetail = () => {
        if (props.setOpenSetting2 != null) props.setOpenSetting2(true)
    }

    return (
        <div style={{ padding: 1 }} onClick={e => clickDetail()}>
            {option != null ?
                <ReactEChartsCore
                    theme={theme.palette.mode}
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: 100 }}
                // onEvents={onEvents}
                />
                : null}
        </div>
    );
}

