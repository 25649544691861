const Dollar100001 = [
    {id: "50", value: "50萬元"},     
    {id: "100", value: "100萬元"},     
    {id: "300", value: "300萬元"},     
    {id: "500", value: "500萬元"}, 
    {id: "600", value: "600萬元"},
    {id: "700", value: "700萬元"},
    {id: "800", value: "800萬元"},
    {id: "900", value: "900萬元"},
    {id: "1000", value: "1,000萬元"},
    {id: "2000", value: "2,000萬元"},
    {id: "3000", value: "3,000萬元"},
    {id: "4000", value: "4,000萬元"},  
    {id: "5000", value: "5,000萬元"},    
    {id: "7000", value: "7,000萬元"},    
    {id: "9000", value: "9,000萬元"},
    {id: "10000", value: "1億元"},
    {id: "30000", value: "3億元"},    
    {id: "50000", value: "5億元"},
    {id: "70000", value: "7億元"},    
    {id: "100000", value: "10億元"},
    {id: "200000", value: "20億元"},
    {id: "300000", value: "30億元"},
    {id: "500000", value: "50億元"},
    {id: "700000", value: "70億元"},    
    {id: "1000000", value: "100億元"},
    {id: "5000000", value: "500億元"},                         
];  
  
  export default Dollar100001  