const Broker = [
{id:"1020", value: "1020 合庫"},       
{id:"1021", value: "1021 合庫- 台中"},
{id:"1022", value: "1022 合庫-台南"},
{id:"1023", value: "1023 合庫-高雄"},
{id:"1024", value: "1024 合庫-嘉義"},
{id:"1025", value: "1025 合庫-基隆"},
{id:"1028", value: "1028 合庫 - 彰化"},
{id:"1029", value: "1029 合庫 - 鳳山"},
{id:"102A", value: "102A 合庫-新竹"},
{id:"102C", value: "102C 合庫-自強"},
{id:"102E", value: "102E 合庫-桃園"},
{id:"102F", value: "102F 合庫-西台中"},
{id:"102G", value: "102G 合庫-三重"},
{id:"102Q", value: "102Q 合庫-國際證券"},
{id:"1030", value: "1030 土銀"},
{id:"1031", value: "1031 土銀-台中"},
{id:"1032", value: "1032 土銀-台南"},
{id:"1033", value: "1033 土銀-高雄"},
{id:"1034", value: "1034 土銀-嘉義"},
{id:"1035", value: "1035 土銀-新竹"},
{id:"1036", value: "1036 土銀-玉里"},
{id:"1037", value: "1037 土銀-花蓮"},
{id:"1038", value: "1038 土銀-和平"},
{id:"1039", value: "1039 土銀-士林"},
{id:"103A", value: "103A 土銀-建國"},
{id:"103B", value: "103B 土銀-彰化"},
{id:"103C", value: "103C 土銀-白河"},
{id:"103F", value: "103F 土銀-南港"},
{id:"1040", value: "1040 臺銀證券"},
{id:"1041", value: "1041 臺銀-鳳山"},
{id:"1042", value: "1042 臺銀-臺南"},
{id:"1043", value: "1043 臺銀-民權"},
{id:"1045", value: "1045 臺銀-新竹"},
{id:"104A", value: "104A 臺銀-臺中"},
{id:"104C", value: "104C 臺銀-高雄"},
{id:"104D", value: "104D 臺銀-金山"},
{id:"1110", value: "1110 台灣企銀"},
{id:"1111", value: "1111 台灣企銀-台中"},
{id:"1112", value: "1112 台灣企銀-台南"},
{id:"1113", value: "1113 台灣企銀-九如"},
{id:"1114", value: "1114 台灣企銀-嘉義"},
{id:"1115", value: "1115 台灣企銀-太平"},
{id:"1116", value: "1116 台灣企銀-屏東"},
{id:"1117", value: "1117 台灣企銀-竹北"},
{id:"1118", value: "1118 台灣企銀-豐原"},
{id:"1119", value: "1119 台灣企銀-岡山"},
{id:"111A", value: "111A 台灣企銀-民雄"},
{id:"111B", value: "111B 台灣企銀-建成"},
{id:"111C", value: "111C 台灣企銀-三民"},
{id:"111E", value: "111E 臺灣企銀-桃園"},
{id:"111F", value: "111F 台灣企銀-北高雄"},
{id:"111G", value: "111G 臺灣企銀-埔墘"},
{id:"1160", value: "1160 日盛"},
{id:"1161", value: "1161 日盛-忠孝"},
{id:"1162", value: "1162 日盛-台南"},
{id:"1163", value: "1163 日盛-台中"},
{id:"1164", value: "1164 日盛-內湖"},
{id:"1165", value: "1165 日盛-板橋"},
{id:"1166", value: "1166 日盛-雙和"},
{id:"1167", value: "1167 日盛-嘉義"},
{id:"1168", value: "1168 日盛-高雄"},
{id:"1169", value: "1169 日盛-士林"},
{id:"116A", value: "116A 日盛-木柵"},
{id:"116B", value: "116B 日盛-中壢"},
{id:"116C", value: "116C 日盛-八德"},
{id:"116E", value: "116E 日盛-三重"},
{id:"116F", value: "116F 日盛-員林"},
{id:"116G", value: "116G 日盛-景美"},
{id:"116H", value: "116H 日盛-新竹"},
{id:"116I", value: "116I 日盛-南京"},
{id:"116J", value: "116J 日盛-板橋中山"},
{id:"116K", value: "116K 日盛-花蓮"},
{id:"116L", value: "116L 日盛-大墩"},
{id:"116M", value: "116M 日盛-屏東"},
{id:"116N", value: "116N 日盛-永康"},
{id:"116P", value: "116P 日盛-信義"},
{id:"116Q", value: "116Q 日盛-二林"},
{id:"116S", value: "116S 日盛-新莊"},
{id:"116U", value: "116U 日盛-桃園"},
{id:"116V", value: "116V 日盛-斗六"},
{id:"116W", value: "116W 日盛-鳳山"},
{id:"116X", value: "116X 日盛-宜蘭"},
{id:"116Z", value: "116Z 日盛-中和"},
{id:"116b", value: "116b 日盛-園區"},
{id:"116c", value: "116c 日盛-豐原"},
{id:"116d", value: "116d 日盛-三峽"},
{id:"116e", value: "116e 日盛-竹北"},
{id:"116f", value: "116f 日盛-復興"},
{id:"116g", value: "116g 日盛-龍潭"},
{id:"116i", value: "116i 日盛-樹林"},
{id:"116j", value: "116j 日盛-頭份"},
{id:"116k", value: "116k 日盛-北高雄"},
{id:"116m", value: "116m 日盛-和美"},
{id:"116r", value: "116r 日盛-文化"},
{id:"116s", value: "116s 日盛-土城"},
{id:"116x", value: "116x 日盛-國際證券"},
{id:"1230", value: "1230 彰銀"},
{id:"1232", value: "1232 彰銀-七賢"},
{id:"1233", value: "1233 彰銀-台中"},
{id:"1260", value: "1260 宏遠"},
{id:"1261", value: "1261 宏遠-民生"},
{id:"1262", value: "1262 宏遠-桃園"},
{id:"126D", value: "126D 宏遠-台南"},
{id:"126H", value: "126H 宏遠-中和"},
{id:"126L", value: "126L 宏遠-台中"},
{id:"126Q", value: "126Q 宏遠-高雄"},
{id:"126U", value: "126U 宏遠-館前"},
{id:"126X", value: "126X 宏遠-新化"},
{id:"1360", value: "1360 港商麥格理"},
{id:"1380", value: "1380 台灣匯立"},
{id:"1440", value: "1440 美林"},
{id:"1470", value: "1470 台灣摩根士丹利"},
{id:"1480", value: "1480 美商高盛"},
{id:"1520", value: "1520 瑞士信貸"},
{id:"1530", value: "1530 港商德意志"},
{id:"1560", value: "1560 港商野村"},
{id:"1570", value: "1570 港商法國興業"},
{id:"1590", value: "1590 花旗環球"},
{id:"1650", value: "1650 新加坡商瑞銀"},
{id:"2180", value: "2180 亞東"},
{id:"2181", value: "2181 亞東-板橋"},
{id:"2184", value: "2184 亞東-新竹"},
{id:"2185", value: "2185 亞東-台南"},
{id:"2186", value: "2186 亞東-高雄"},
{id:"2187", value: "2187 亞東-台中"},
{id:"218A", value: "218A 亞東-國際證券"},
{id:"2200", value: "2200 元大期貨"},
{id:"2210", value: "2210 群益期貨"},
{id:"5050", value: "5050 大展"},
{id:"5058", value: "5058 大展-台南"},
{id:"5110", value: "5110 富隆"},
{id:"5112", value: "5112 富隆-長安"},
{id:"5260", value: "5260 大慶"},
{id:"5261", value: "5261 大慶-蘆洲"},
{id:"5262", value: "5262 大慶-楊梅"},
{id:"5263", value: "5263 大慶-泰山"},
{id:"5264", value: "5264 大慶-高雄"},
{id:"5265", value: "5265 大慶-苗栗"},
{id:"5266", value: "5266 大慶-中壢"},
{id:"5267", value: "5267 大慶-基隆"},
{id:"5268", value: "5268 大慶-台南"},
{id:"5269", value: "5269 大慶-台中"},
{id:"526A", value: "526A 大慶-中和"},
{id:"526K", value: "526K 大慶-富順"},
{id:"526L", value: "526L 大慶-長榮"},
{id:"5320", value: "5320 高橋"},
{id:"5321", value: "5321 高橋-龍潭"},
{id:"5322", value: "5322 高橋-中壢"},
{id:"5323", value: "5323 高橋-內壢"},
{id:"5380", value: "5380 第一金"},
{id:"5381", value: "5381 第一金-員林"},
{id:"5382", value: "5382 第一金-台中"},
{id:"5383", value: "5383 第一金-高雄"},
{id:"5384", value: "5384 第一金-台南"},
{id:"5385", value: "5385 第一金-桃園"},
{id:"5386", value: "5386 第一金-彰化"},
{id:"5387", value: "5387 第一金-澎湖"},
{id:"5389", value: "5389 第一金-中山"},
{id:"538A", value: "538A 第一金-新興"},
{id:"538B", value: "538B 第一金-新竹"},
{id:"538C", value: "538C 第一金-光復"},
{id:"538D", value: "538D 第一金-忠孝"},
{id:"538E", value: "538E 第一金-自由"},
{id:"538F", value: "538F 第一金-大稻埕"},
{id:"538I", value: "538I 第一金-頭份"},
{id:"538M", value: "538M 第一金-嘉義"},
{id:"538N", value: "538N 第一金-豐原"},
{id:"538P", value: "538P 第一金-路竹"},
{id:"538W", value: "538W 第一金-華江"},
{id:"538Y", value: "538Y 第一金-中壢"},
{id:"538a", value: "538a 第一金-國際證券"},
{id:"538j", value: "538j 第一金-安和"},
{id:"5460", value: "5460 寶盛"},
{id:"5600", value: "5600 永興"},
{id:"5602", value: "5602 永興-水湳"},
{id:"5603", value: "5603 永興-台中"},
{id:"5604", value: "5604 永興-大墩"},
{id:"5660", value: "5660 日進"},
{id:"5850", value: "5850 統一"},
{id:"5851", value: "5851 統一-高雄"},
{id:"5852", value: "5852 統一-敦南"},
{id:"5853", value: "5853 統一-中壢"},
{id:"5854", value: "5854 統一-城中"},
{id:"5855", value: "5855 統一-台南"},
{id:"5856", value: "5856 統一-台中"},
{id:"5857", value: "5857 統一-新竹"},
{id:"5858", value: "5858 統一-嘉義"},
{id:"5859", value: "5859 統一-屏東"},
{id:"585A", value: "585A 統一-基隆"},
{id:"585B", value: "585B 統一-永和"},
{id:"585D", value: "585D 統一-新台中"},
{id:"585E", value: "585E 統一-新營"},
{id:"585F", value: "585F 統一-彰化"},
{id:"585H", value: "585H 統一-桃園"},
{id:"585I", value: "585I 統一-員林"},
{id:"585J", value: "585J 統一-三重"},
{id:"585M", value: "585M 統一-士林"},
{id:"585P", value: "585P 統一-板橋"},
{id:"585Q", value: "585Q 統一-三多"},
{id:"585R", value: "585R 統一-汐止"},
{id:"585S", value: "585S 統一-宜蘭"},
{id:"585U", value: "585U 統一-南京"},
{id:"585W", value: "585W 統一-金門"},
{id:"585Y", value: "585Y 統一-土城"},
{id:"585Z", value: "585Z 統一-松江"},
{id:"585b", value: "585b 統一-內湖"},
{id:"585c", value: "585c 統一-仁愛"},
{id:"585g", value: "585g 統一-平鎮"},
{id:"585m", value: "585m 統一-竹南"},
{id:"585s", value: "585s 統一-國際證券"},
{id:"5860", value: "5860 盈溢"},
{id:"5861", value: "5861 盈溢-籬子內"},
{id:"5862", value: "5862 盈溢-楠梓"},
{id:"5870", value: "5870 光隆"},
{id:"5920", value: "5920 元富"},
{id:"5921", value: "5921 元富-高雄"},
{id:"5922", value: "5922 元富-新店"},
{id:"5923", value: "5923 元富-三重"},
{id:"5925", value: "5925 元富-台中"},
{id:"5926", value: "5926 元富-四維"},
{id:"5927", value: "5927 元富-林園"},
{id:"5928", value: "5928 元富-新莊"},
{id:"5929", value: "5929 元富-台南"},
{id:"592A", value: "592A 元富-緯城"},
{id:"592B", value: "592B 元富-永和"},
{id:"592C", value: "592C 元富-潮州"},
{id:"592D", value: "592D 元富-大昌"},
{id:"592E", value: "592E 元富-城中"},
{id:"592G", value: "592G 元富-新竹"},
{id:"592H", value: "592H 元富-信義"},
{id:"592I", value: "592I 元富-嘉義"},
{id:"592L", value: "592L 元富-中壢"},
{id:"592M", value: "592M 元富-彰化"},
{id:"592N", value: "592N 元富-虎尾"},
{id:"592P", value: "592P 元富-同大"},
{id:"592Q", value: "592Q 元富-敦南"},
{id:"592S", value: "592S 元富-基隆"},
{id:"592U", value: "592U 元富-宜蘭"},
{id:"592V", value: "592V 元富-民權"},
{id:"592W", value: "592W 元富-吉利"},
{id:"592X", value: "592X 元富-草屯"},
{id:"592Y", value: "592Y 元富-埔里"},
{id:"592Z", value: "592Z 元富-安南"},
{id:"592a", value: "592a 元富-松德"},
{id:"592b", value: "592b 元富-板橋"},
{id:"592c", value: "592c 元富-烏日"},
{id:"592d", value: "592d 元富-延平"},
{id:"592e", value: "592e 元富-古亭"},
{id:"592f", value: "592f 元富-國際證券"},
{id:"592g", value: "592g 元富-中原"},
{id:"592i", value: "592i 元富-中港"},
{id:"592l", value: "592l 元富-大裕"},
{id:"592m", value: "592m 元富-成功"},
{id:"592n", value: "592n 元富-西松"},
{id:"592o", value: "592o 元富-新興"},
{id:"592q", value: "592q 元富-屏東"},
{id:"592r", value: "592r 元富-城東"},
{id:"592u", value: "592u 元富-世貿"},
{id:"592v", value: "592v 元富-桃園"},
{id:"592w", value: "592w 元富-佳里"},
{id:"592y", value: "592y 元富-花蓮"},
{id:"592z", value: "592z 元富-文心"},
{id:"5960", value: "5960 日茂"},
{id:"5961", value: "5961 日茂-埔里"},
{id:"5962", value: "5962 日茂-南投"},
{id:"6010", value: "6010 犇亞證券"},
{id:"6012", value: "6012 犇亞-網路"},
{id:"601d", value: "601d 犇亞-鑫豐"},
{id:"6110", value: "6110 台中銀"},
{id:"6114", value: "6114 台中銀-員林"},
{id:"6115", value: "6115 台中銀-台北"},
{id:"6116", value: "6116 台中銀-中壢"},
{id:"611A", value: "611A 台中銀-豐原"},
{id:"6160", value: "6160 中國信託"},
{id:"6161", value: "6161 中國信託-三重"},
{id:"6162", value: "6162 中國信託-忠孝"},
{id:"6163", value: "6163 中國信託-永康"},
{id:"6164", value: "6164 中國信託-文心"},
{id:"6165", value: "6165 中國信託-新竹"},
{id:"6167", value: "6167 中國信託-松江"},
{id:"6168", value: "6168 中國信託-嘉義"},
{id:"616A", value: "616A 中國信託-中壢"},
{id:"616K", value: "616K 中國信託-高雄"},
{id:"616X", value: "616X 中國信託-國際證券"},
{id:"6210", value: "6210 新百王"},
{id:"6380", value: "6380 光和"},
{id:"6381", value: "6381 光和-溪湖"},
{id:"6382", value: "6382 光和-田中"},
{id:"6383", value: "6383 光和-和美"},
{id:"6385", value: "6385 光和-花壇"},
{id:"6386", value: "6386 光和-虎尾"},
{id:"638A", value: "638A 光和-嘉義"},
{id:"638D", value: "638D 光和-高雄"},
{id:"6450", value: "6450 永全"},
{id:"6451", value: "6451 永全-南崁"},
{id:"6452", value: "6452 永全-八德"},
{id:"6460", value: "6460 大昌"},
{id:"6461", value: "6461 大昌-樹林"},
{id:"6462", value: "6462 大昌-安康"},
{id:"6463", value: "6463 大昌-新竹"},
{id:"6464", value: "6464 大昌-新店"},
{id:"6465", value: "6465 大昌-桃園"},
{id:"6480", value: "6480 福邦"},
{id:"6489", value: "6489 福邦-新竹"},
{id:"6620", value: "6620 全泰"},
{id:"6910", value: "6910 德信"},
{id:"6912", value: "6912 德信-中正"},
{id:"6913", value: "6913 德信-新營"},
{id:"6914", value: "6914 德信-三重"},
{id:"6915", value: "6915 德信-和平"},
{id:"6950", value: "6950 福勝"},
{id:"7000", value: "7000 兆豐"},
{id:"7001", value: "7001 兆豐-嘉義"},
{id:"7003", value: "7003 兆豐-台中"},
{id:"7005", value: "7005 兆豐-台中港"},
{id:"7006", value: "7006 兆豐-台南"},
{id:"7007", value: "7007 兆豐-竹北"},
{id:"7008", value: "7008 兆豐-三重"},
{id:"7009", value: "7009 兆豐-景美"},
{id:"700B", value: "700B 兆豐-板橋"},
{id:"700C", value: "700C 兆豐-來福"},
{id:"700D", value: "700D 兆豐-西螺"},
{id:"700E", value: "700E 兆豐-斗南"},
{id:"700F", value: "700F 兆豐-虎尾"},
{id:"700G", value: "700G 兆豐-麻豆"},
{id:"700H", value: "700H 兆豐-東門"},
{id:"700I", value: "700I 兆豐-北高雄"},
{id:"700J", value: "700J 兆豐-公益"},
{id:"700K", value: "700K 兆豐-新營"},
{id:"700L", value: "700L 兆豐-天母"},
{id:"700M", value: "700M 兆豐-桃園"},
{id:"700N", value: "700N 兆豐-埔墘"},
{id:"700P", value: "700P 兆豐-南京"},
{id:"700R", value: "700R 兆豐-小港"},
{id:"700S", value: "700S 兆豐-大同"},
{id:"700U", value: "700U 兆豐-桃鶯"},
{id:"700V", value: "700V 兆豐-新竹"},
{id:"700W", value: "700W 兆豐-城中"},
{id:"700X", value: "700X 兆豐-永和"},
{id:"700Z", value: "700Z 兆豐-高雄"},
{id:"700a", value: "700a 兆豐-忠孝"},
{id:"700b", value: "700b 兆豐-中壢"},
{id:"700c", value: "700c 兆豐-民生"},
{id:"700d", value: "700d 兆豐-彰化"},
{id:"700f", value: "700f 兆豐-鹿港"},
{id:"700g", value: "700g 兆豐-南門"},
{id:"700h", value: "700h 兆豐-三民"},
{id:"700i", value: "700i 兆豐-松德"},
{id:"700j", value: "700j 兆豐-大安"},
{id:"700k", value: "700k 兆豐-員林"},
{id:"700m", value: "700m 兆豐-國際證券"},
{id:"700p", value: "700p 兆豐-復興"},
{id:"700q", value: "700q 兆豐-內湖"},
{id:"700r", value: "700r 兆豐-寶成"},
{id:"700s", value: "700s 兆豐-岡山"},
{id:"700w", value: "700w 兆豐-新莊"},
{id:"7030", value: "7030 致和"},
{id:"7031", value: "7031 致和-台北"},
{id:"7032", value: "7032 致和-佳里"},
{id:"7033", value: "7033 致和-府前"},
{id:"7034", value: "7034 致和-金華"},
{id:"7035", value: "7035 致和-東門"},
{id:"7036", value: "7036 致和-高雄"},
{id:"7038", value: "7038 致和-南京"},
{id:"703B", value: "703B 致和-崇德"},
{id:"7070", value: "7070 豐農"},
{id:"7080", value: "7080 石橋"},
{id:"7750", value: "7750 北城"},
{id:"7790", value: "7790 國票"},
{id:"7791", value: "7791 國票-台東"},
{id:"7792", value: "7792 國票-北投"},
{id:"7795", value: "7795 國票-南港"},
{id:"7797", value: "7797 國票-中正"},
{id:"7798", value: "7798 國票-內壢"},
{id:"779D", value: "779D 國票-台南"},
{id:"779E", value: "779E 國票-南崁"},
{id:"779G", value: "779G 國票-新莊"},
{id:"779H", value: "779H 國票-嘉義"},
{id:"779J", value: "779J 國票-北高雄"},
{id:"779P", value: "779P 國票-九鼎"},
{id:"779V", value: "779V 國票-天祥"},
{id:"779W", value: "779W 國票-彰化"},
{id:"779X", value: "779X 國票-中和"},
{id:"779Z", value: "779Z 國票-安和"},
{id:"779b", value: "779b 國票-國際證券"},
{id:"779c", value: "779c 國票-敦北法人"},
{id:"779e", value: "779e 國票-南科"},
{id:"779j", value: "779j 國票-和平"},
{id:"779m", value: "779m 國票-中港"},
{id:"779n", value: "779n 國票-南京"},
{id:"779r", value: "779r 國票-桃園"},
{id:"779u", value: "779u 國票-長城"},
{id:"779v", value: "779v 國票-台中"},
{id:"779z", value: "779z 國票-博愛"},
{id:"8150", value: "8150 台新"},
{id:"8151", value: "8151 台新-建北"},
{id:"8152", value: "8152 台新-新莊"},
{id:"8156", value: "8156 台新-三民"},
{id:"8157", value: "8157 台新-左楠"},
{id:"8158", value: "8158 台新-松江"},
{id:"8159", value: "8159 台新-台南"},
{id:"815A", value: "815A 台新-高雄"},
{id:"815B", value: "815B 台新-台中"},
{id:"815H", value: "815H 台新-屏東"},
{id:"815S", value: "815S 台新-中壢"},
{id:"815Y", value: "815Y 台新-新營"},
{id:"815Z", value: "815Z 台新-國際證券"},
{id:"8380", value: "8380 安泰"},
{id:"8381", value: "8381 安泰-大發"},
{id:"8382", value: "8382 安泰-楠梓"},
{id:"8440", value: "8440 摩根大通"},
{id:"8450", value: "8450 康和"},
{id:"8451", value: "8451 康和-延平"},
{id:"8455", value: "8455 康和-台中"},
{id:"8456", value: "8456 康和-新竹"},
{id:"8458", value: "8458 康和-台南"},
{id:"8459", value: "8459 康和-嘉義"},
{id:"845A", value: "845A 康和-內湖"},
{id:"845B", value: "845B 康和-永和"},
{id:"845D", value: "845D 康和-板橋"},
{id:"845E", value: "845E 康和-澎湖"},
{id:"845F", value: "845F 康和-仁愛"},
{id:"845J", value: "845J 康和-高雄"},
{id:"845R", value: "845R 康和-石牌"},
{id:"845S", value: "845S 康和-南崁"},
{id:"845U", value: "845U 康和-台北"},
{id:"845X", value: "845X 康和-屏東"},
{id:"845Y", value: "845Y 康和-國際證券"},
{id:"8490", value: "8490 萬泰"},
{id:"8520", value: "8520 中農"},
{id:"8560", value: "8560 新光"},
{id:"8561", value: "8561 新光-台中"},
{id:"8562", value: "8562 新光 - 高雄"},
{id:"8563", value: "8563 新光 - 新竹"},
{id:"8564", value: "8564 新光-台南"},
{id:"8565", value: "8565 新光-桃園"},
{id:"8580", value: "8580 聯邦商銀"},
{id:"8581", value: "8581 聯邦-高雄"},
{id:"8582", value: "8582 聯邦-嘉義"},
{id:"8583", value: "8583 聯邦-敦化"},
{id:"8584", value: "8584 聯邦-雙和"},
{id:"8585", value: "8585 聯邦-三重"},
{id:"8586", value: "8586 聯邦-大業"},
{id:"8587", value: "8587 聯邦-興中"},
{id:"8588", value: "8588 聯邦-富強"},
{id:"8710", value: "8710 陽信"},
{id:"8711", value: "8711 陽信-石牌"},
{id:"8840", value: "8840 玉山"},
{id:"8841", value: "8841 玉山-雙和"},
{id:"8842", value: "8842 玉山-新莊"},
{id:"8843", value: "8843 玉山-高雄"},
{id:"8844", value: "8844 玉山-松江"},
{id:"8846", value: "8846 玉山-板橋"},
{id:"8847", value: "8847 玉山-台南"},
{id:"8848", value: "8848 玉山-嘉義"},
{id:"8849", value: "8849 玉山-台大"},
{id:"884A", value: "884A 玉山-士林"},
{id:"884B", value: "884B 玉山-台中"},
{id:"884C", value: "884C 玉山-南京東路"},
{id:"884D", value: "884D 玉山-左營"},
{id:"884E", value: "884E 玉山-城中"},
{id:"884F", value: "884F 玉山-桃園"},
{id:"884H", value: "884H 玉山-新竹"},
{id:"884J", value: "884J 玉山-大里"},
{id:"884L", value: "884L 玉山-國際證券"},
{id:"8880", value: "8880 國泰"},
{id:"8881", value: "8881 國泰-高雄"},
{id:"8882", value: "8882 國泰-台中"},
{id:"8883", value: "8883 國泰-松江"},
{id:"8884", value: "8884 國泰-台南"},
{id:"8885", value: "8885 國泰-桃園"},
{id:"8886", value: "8886 國泰-新莊"},
{id:"8887", value: "8887 國泰-忠孝"},
{id:"8888", value: "8888 國泰-敦南"},
{id:"888A", value: "888A 國泰-館前"},
{id:"888K", value: "888K 國泰-板橋"},
{id:"888Q", value: "888Q 國泰-國際證券"},
{id:"8890", value: "8890 大和國泰"},
{id:"8900", value: "8900 法銀巴黎"},
{id:"8960", value: "8960 香港上海匯豐"},
{id:"9100", value: "9100 群益金鼎"},
{id:"9101", value: "9101 群益金鼎-敦南"},
{id:"9103", value: "9103 群益金鼎-松山"},
{id:"9105", value: "9105 群益金鼎-高盛"},
{id:"9108", value: "9108 群益金鼎-海山"},
{id:"910D", value: "910D 群益金鼎-大興"},
{id:"9131", value: "9131 群益金鼎-民權"},
{id:"9134", value: "9134 群益金鼎-開元"},
{id:"9135", value: "9135 群益金鼎-經國"},
{id:"9136", value: "9136 群益金鼎-西松"},
{id:"9137", value: "9137 群益金鼎-士林"},
{id:"9138", value: "9138 群益金鼎-中港"},
{id:"913D", value: "913D 群益金鼎-潭子"},
{id:"913E", value: "913E 群益金鼎-基隆"},
{id:"913H", value: "913H 群益金鼎-屏東"},
{id:"913I", value: "913I 群益金鼎-萬華"},
{id:"913N", value: "913N 群益金鼎-土城"},
{id:"913R", value: "913R 群益金鼎-北高雄"},
{id:"913U", value: "913U 群益金鼎-崇德"},
{id:"913Y", value: "913Y 群益金鼎-館前"},
{id:"913g", value: "913g 群益金鼎-南三重"},
{id:"9183", value: "9183 群益金鼎-高雄"},
{id:"9184", value: "9184 群益金鼎-台中"},
{id:"9185", value: "9185 群益金鼎-台南"},
{id:"9186", value: "9186 群益金鼎-新竹"},
{id:"9187", value: "9187 群益金鼎-彰化"},
{id:"9188", value: "9188 群益金鼎-三民"},
{id:"9189", value: "9189 群益金鼎-桃園"},
{id:"918A", value: "918A 群益金鼎-中壢"},
{id:"918B", value: "918B 群益金鼎-板橋"},
{id:"918C", value: "918C 群益金鼎-古亭"},
{id:"918D", value: "918D 群益金鼎-東門"},
{id:"918E", value: "918E 群益金鼎-南京"},
{id:"918F", value: "918F 群益金鼎-新店"},
{id:"918G", value: "918G 群益金鼎-新莊"},
{id:"918H", value: "918H 群益金鼎-中山"},
{id:"918J", value: "918J 群益金鼎-永和"},
{id:"918K", value: "918K 群益金鼎-東湖"},
{id:"918L", value: "918L 群益金鼎-建成"},
{id:"918M", value: "918M 群益金鼎-嘉義"},
{id:"918P", value: "918P 群益金鼎-天母"},
{id:"918W", value: "918W 群益金鼎-忠孝"},
{id:"918X", value: "918X 群益金鼎-台北"},
{id:"918c", value: "918c 群益金鼎-鳳山"},
{id:"918d", value: "918d 群益金鼎-內湖"},
{id:"918e", value: "918e 群益金鼎-大安"},
{id:"918g", value: "918g 群益金鼎-大甲"},
{id:"918i", value: "918i 群益金鼎-竹南"},
{id:"918s", value: "918s 群益金鼎-丹鳳"},
{id:"918u", value: "918u 群益金鼎-瑞豐"},
{id:"918x", value: "918x 群益金鼎-延平"},
{id:"918z", value: "918z 群益金鼎-宜蘭"},
{id:"9199", value: "9199 群益金鼎-國際證券"},
{id:"9200", value: "9200 凱基"},
{id:"9202", value: "9202 凱基-中港"},
{id:"9204", value: "9204 凱基-台中"},
{id:"9205", value: "9205 凱基-三重"},
{id:"9206", value: "9206 凱基-高雄"},
{id:"9207", value: "9207 凱基-永和"},
{id:"9208", value: "9208 凱基-桃園"},
{id:"9209", value: "9209 凱基-岡山"},
{id:"920A", value: "920A 凱基-板橋"},
{id:"920C", value: "920C 凱基-雙和"},
{id:"920D", value: "920D 凱基-市府"},
{id:"920E", value: "920E 凱基-東門"},
{id:"920F", value: "920F 凱基-站前"},
{id:"920G", value: "920G 凱基-彰化"},
{id:"920H", value: "920H 凱基-基隆"},
{id:"920M", value: "920M 凱基-宜蘭"},
{id:"920N", value: "920N 凱基-湖口"},
{id:"920V", value: "920V 凱基-新店"},
{id:"920W", value: "920W 凱基-大里"},
{id:"920Z", value: "920Z 凱基-台東"},
{id:"9211", value: "9211 凱基-台南"},
{id:"9212", value: "9212 凱基-東港"},
{id:"9215", value: "9215 凱基-高美館"},
{id:"9216", value: "9216 凱基-信義"},
{id:"9217", value: "9217 凱基-松山"},
{id:"9218", value: "9218 凱基-大直"},
{id:"921C", value: "921C 凱基-民權"},
{id:"921E", value: "921E 凱基-三峽"},
{id:"921F", value: "921F 凱基-天母"},
{id:"921G", value: "921G 凱基-五股"},
{id:"921J", value: "921J 凱基-土城"},
{id:"921M", value: "921M 凱基-八德"},
{id:"921S", value: "921S 凱基-幸福"},
{id:"921Y", value: "921Y 凱基-東勢"},
{id:"9223", value: "9223 凱基-豐中"},
{id:"9224", value: "9224 凱基-新莊"},
{id:"9225", value: "9225 凱基-內埔"},
{id:"9226", value: "9226 凱基-汐止"},
{id:"9227", value: "9227 凱基-城中"},
{id:"9229", value: "9229 凱基-中山"},
{id:"922C", value: "922C 凱基-屏東"},
{id:"922H", value: "922H 凱基-復興"},
{id:"9231", value: "9231 凱基-員林"},
{id:"9233", value: "9233 凱基-長庚"},
{id:"9234", value: "9234 凱基-竹北"},
{id:"9235", value: "9235 凱基-永華"},
{id:"9236", value: "9236 凱基-虎尾"},
{id:"9237", value: "9237 凱基-敦北"},
{id:"9238", value: "9238 凱基-士林"},
{id:"9239", value: "9239 凱基-市政"},
{id:"9252", value: "9252 凱基-嘉義"},
{id:"9254", value: "9254 凱基-科園"},
{id:"9255", value: "9255 凱基-和平"},
{id:"9256", value: "9256 凱基-南崁"},
{id:"9257", value: "9257 凱基-林口"},
{id:"9258", value: "9258 凱基-羅東"},
{id:"9266", value: "9266 凱基-新豐"},
{id:"9268", value: "9268 凱基-台北"},
{id:"9272", value: "9272 凱基-竹科"},
{id:"9273", value: "9273 凱基-竹東"},
{id:"9274", value: "9274 凱基-鳳山"},
{id:"9275", value: "9275 凱基-三多"},
{id:"9276", value: "9276 凱基-苗栗"},
{id:"9278", value: "9278 凱基-永康"},
{id:"9281", value: "9281 凱基-斗六"},
{id:"9283", value: "9283 凱基-北門"},
{id:"9285", value: "9285 凱基-中壢"},
{id:"9287", value: "9287 凱基-內湖"},
{id:"9288", value: "9288 凱基-埔墘"},
{id:"9289", value: "9289 凱基-興隆"},
{id:"9291", value: "9291 凱基-大安"},
{id:"9296", value: "9296 凱基-頭份"},
{id:"9297", value: "9297 凱基-文心"},
{id:"9299", value: "9299 凱基-國際證券"},
{id:"9300", value: "9300 華南永昌"},
{id:"9302", value: "9302 華南永昌-台中"},
{id:"9303", value: "9303 華南永昌-高雄"},
{id:"9305", value: "9305 華南永昌-新莊"},
{id:"9306", value: "9306 華南永昌-台南"},
{id:"9307", value: "9307 華南永昌-大安"},
{id:"9308", value: "9308 華南永昌-麻豆"},
{id:"9309", value: "9309 華南永昌-古亭"},
{id:"9312", value: "9312 華南永昌-民權"},
{id:"9314", value: "9314 華南永昌-嘉義"},
{id:"9315", value: "9315 華南永昌-苑裡"},
{id:"9316", value: "9316 華南永昌-淡水"},
{id:"9317", value: "9317 華南永昌-林口"},
{id:"9319", value: "9319 華南永昌-鶯歌"},
{id:"9322", value: "9322 華南永昌-公誠"},
{id:"9323", value: "9323 華南永昌-小港"},
{id:"9324", value: "9324 華南永昌-岡山"},
{id:"9325", value: "9325 華南永昌-忠孝"},
{id:"9326", value: "9326 華南永昌-南京"},
{id:"9327", value: "9327 華南永昌-斗六"},
{id:"9328", value: "9328 華南永昌-潮州"},
{id:"9329", value: "9329 華南永昌-朴子"},
{id:"9331", value: "9331 華南永昌-鳳山"},
{id:"9332", value: "9332 華南永昌-楠梓"},
{id:"9333", value: "9333 華南永昌-長虹"},
{id:"9334", value: "9334 華南永昌-世貿"},
{id:"9337", value: "9337 華南永昌-內壢"},
{id:"9339", value: "9339 華南永昌-竹北"},
{id:"9343", value: "9343 華南永昌-頭份"},
{id:"9347", value: "9347 華南永昌-敦南"},
{id:"9349", value: "9349 華南永昌-合泰"},
{id:"9352", value: "9352 華南永昌-桃園"},
{id:"9358", value: "9358 華南永昌-東勢"},
{id:"9359", value: "9359 華南永昌-中正"},
{id:"9362", value: "9362 華南永昌-苗栗"},
{id:"9363", value: "9363 華南永昌-彰化"},
{id:"9369", value: "9369 華南永昌-三重"},
{id:"9377", value: "9377 華南永昌-虎尾"},
{id:"9386", value: "9386 華南永昌-東昇"},
{id:"9399", value: "9399 華南永昌-國際證券"},
{id:"9600", value: "9600 富邦"},
{id:"9604", value: "9604 富邦-陽明"},
{id:"9608", value: "9608 富邦-台東"},
{id:"960S", value: "960S 富邦-國際證券"},
{id:"9614", value: "9614 富邦-基隆"},
{id:"9616", value: "9616 富邦-岡山"},
{id:"9621", value: "9621 富邦-花蓮"},
{id:"9622", value: "9622 富邦-園區"},
{id:"9623", value: "9623 富邦-台北"},
{id:"9624", value: "9624 富邦-竹北"},
{id:"9625", value: "9625 富邦-竹東"},
{id:"9627", value: "9627 富邦-內湖"},
{id:"9634", value: "9634 富邦-羅東"},
{id:"9636", value: "9636 富邦-中壢"},
{id:"9647", value: "9647 富邦-新竹華信"},
{id:"9651", value: "9651 富邦-民生"},
{id:"9654", value: "9654 富邦-永和"},
{id:"9655", value: "9655 富邦-板橋"},
{id:"9657", value: "9657 富邦-苗栗"},
{id:"9658", value: "9658 富邦-建國"},
{id:"9659", value: "9659 富邦-高雄"},
{id:"965K", value: "965K 富邦-台中"},
{id:"965U", value: "965U 富邦-彰化"},
{id:"9661", value: "9661 富邦-新店"},
{id:"9663", value: "9663 富邦-敦南"},
{id:"9665", value: "9665 富邦-桃園"},
{id:"9666", value: "9666 富邦-南屯"},
{id:"9667", value: "9667 富邦-台南"},
{id:"966F", value: "966F 富邦-左營"},
{id:"9672", value: "9672 富邦-員林"},
{id:"9676", value: "9676 富邦-仁愛"},
{id:"9677", value: "9677 富邦-三重"},
{id:"9679", value: "9679 富邦-延吉"},
{id:"9686", value: "9686 富邦-宜蘭"},
{id:"9692", value: "9692 富邦-嘉義"},
{id:"9693", value: "9693 富邦-新營"},
{id:"9695", value: "9695 富邦-民雄"},
{id:"9697", value: "9697 富邦-虎尾"},
{id:"969C", value: "969C 富邦-北港"},
{id:"9800", value: "9800 元大"},
{id:"9801", value: "9801 元大-松江"},
{id:"980C", value: "980C 元大- 斗六"},
{id:"980D", value: "980D 元大-開元"},
{id:"980K", value: "980K 元大-竹科"},
{id:"980Q", value: "980Q 元大-南崁"},
{id:"980R", value: "980R 元大-南海"},
{id:"980a", value: "980a 元大-承德"},
{id:"980d", value: "980d 元大-沙鹿"},
{id:"980e", value: "980e 元大-清水"},
{id:"980h", value: "980h 元大-台北"},
{id:"980l", value: "980l 元大-虎尾"},
{id:"980u", value: "980u 元大-府城"},
{id:"980w", value: "980w 元大-西屯"},
{id:"9812", value: "9812 元大-台中"},
{id:"9813", value: "9813 元大-八德"},
{id:"9814", value: "9814 元大-大益"},
{id:"9815", value: "9815 元大-大灣"},
{id:"9816", value: "9816 元大-新竹"},
{id:"9817", value: "9817 元大-東泰"},
{id:"981A", value: "981A 元大-鶯歌"},
{id:"981B", value: "981B 元大-竹北"},
{id:"981C", value: "981C 元大-路竹"},
{id:"981D", value: "981D 元大-南投"},
{id:"981E", value: "981E 元大-發財"},
{id:"981F", value: "981F 元大-福營"},
{id:"981G", value: "981G 元大-彰化"},
{id:"981I", value: "981I 元大-天母"},
{id:"981K", value: "981K 元大-林園"},
{id:"981L", value: "981L 元大-莒光"},
{id:"981M", value: "981M 元大-苗栗"},
{id:"981N", value: "981N 元大-金門"},
{id:"981Q", value: "981Q 元大-太平"},
{id:"981R", value: "981R 元大-竹山"},
{id:"981S", value: "981S 元大-崇德"},
{id:"981U", value: "981U 元大-華山"},
{id:"981V", value: "981V 元大-民雄"},
{id:"981X", value: "981X 元大-東門"},
{id:"981Z", value: "981Z 元大-潮州"},
{id:"981a", value: "981a 元大-歸仁"},
{id:"981e", value: "981e 元大-佳里"},
{id:"981g", value: "981g 元大-大里"},
{id:"981h", value: "981h 元大-屏東民生"},
{id:"981i", value: "981i 元大-西螺"},
{id:"981j", value: "981j 元大-士林"},
{id:"981m", value: "981m 元大-前金"},
{id:"981n", value: "981n 元大-旗山"},
{id:"981p", value: "981p 元大-松山"},
{id:"981q", value: "981q 元大-萬華"},
{id:"981r", value: "981r 元大-屏南"},
{id:"981y", value: "981y 元大-善化"},
{id:"981z", value: "981z 元大-大統"},
{id:"9822", value: "9822 元大-土城學府"},
{id:"9824", value: "9824 元大-向上"},
{id:"9825", value: "9825 元大-新營"},
{id:"9829", value: "9829 元大-永康"},
{id:"982A", value: "982A 元大-羅東"},
{id:"982B", value: "982B 元大-博愛"},
{id:"982C", value: "982C 元大-六合"},
{id:"982F", value: "982F 元大-忠孝鼎富"},
{id:"9831", value: "9831 元大-四維"},
{id:"9833", value: "9833 元大-敦化"},
{id:"9834", value: "9834 元大-中壢"},
{id:"9835", value: "9835 元大-員林中山"},
{id:"9837", value: "9837 元大-北港"},
{id:"9838", value: "9838 元大-豐原"},
{id:"983B", value: "983B 元大-林森"},
{id:"983G", value: "983G 元大-上新莊"},
{id:"983K", value: "983K 元大-學甲"},
{id:"983M", value: "983M 元大-木柵"},
{id:"983N", value: "983N 元大-大安"},
{id:"983U", value: "983U 元大-頭份"},
{id:"983V", value: "983V 元大-竹南"},
{id:"983W", value: "983W 元大-大里德芳"},
{id:"983Z", value: "983Z 元大-鳳山"},
{id:"983f", value: "983f 元大-竹東"},
{id:"983i", value: "983i 元大-大雅"},
{id:"983j", value: "983j 元大-仁愛"},
{id:"984C", value: "984C 元大-新店中正"},
{id:"984E", value: "984E 元大-南勢角"},
{id:"984H", value: "984H 元大-景美"},
{id:"984K", value: "984K 元大-館前"},
{id:"9852", value: "9852 元大-大松山"},
{id:"9853", value: "9853 元大-南屯"},
{id:"9854", value: "9854 元大-文心興安"},
{id:"9856", value: "9856 元大-花蓮"},
{id:"9857", value: "9857 元大-東蘆"},
{id:"9858", value: "9858 元大-龍潭"},
{id:"9859", value: "9859 元大-新竹經國"},
{id:"985C", value: "985C 元大-鳳中"},
{id:"9862", value: "9862 元大-南京"},
{id:"9863", value: "9863 元大-新和平"},
{id:"9867", value: "9867 元大-北成功"},
{id:"9868", value: "9868 元大-文心"},
{id:"9869", value: "9869 元大-新盛"},
{id:"986K", value: "986K 元大-基隆孝二"},
{id:"9871", value: "9871 元大-忠孝"},
{id:"9872", value: "9872 元大-復北"},
{id:"9873", value: "9873 元大-西門"},
{id:"9874", value: "9874 元大-雙和"},
{id:"9875", value: "9875 元大-土城永寧"},
{id:"9878", value: "9878 元大-金華"},
{id:"9879", value: "9879 元大-板橋三民"},
{id:"987A", value: "987A 元大-楊梅"},
{id:"9884", value: "9884 元大-鎮北"},
{id:"9888", value: "9888 元大-鑫永和"},
{id:"9889", value: "9889 元大-員林"},
{id:"988B", value: "988B 元大-桃園"},
{id:"988C", value: "988C 元大-三重"},
{id:"988V", value: "988V 元大-長庚"},
{id:"9891", value: "9891 元大-永和"},
{id:"9892", value: "9892 元大-屏東"},
{id:"9893", value: "9893 元大-大天母"},
{id:"9894", value: "9894 元大-桃興"},
{id:"9896", value: "9896 元大-台南"},
{id:"9897", value: "9897 元大-土城"},
{id:"9898", value: "9898 元大-新莊"},
{id:"9899", value: "9899 元大-淡水"},
{id:"989A", value: "989A 元大-北投"},
{id:"989B", value: "989B 元大-台中中港"},
{id:"989C", value: "989C 元大-板橋"},
{id:"989D", value: "989D 元大-左營"},
{id:"989E", value: "989E 元大-草屯"},
{id:"989F", value: "989F 元大-高雄"},
{id:"989G", value: "989G 元大-大同"},
{id:"989I", value: "989I 元大-蘆洲"},
{id:"989J", value: "989J 元大-彰化民生"},
{id:"989L", value: "989L 元大-成功"},
{id:"989N", value: "989N 元大-內湖"},
{id:"989P", value: "989P 元大-敦南"},
{id:"989Q", value: "989Q 元大-汐止"},
{id:"989S", value: "989S 元大-鹿港"},
{id:"989U", value: "989U 元大-大甲"},
{id:"989V", value: "989V 元大-北三重"},
{id:"989W", value: "989W 元大-豐原站前"},
{id:"989X", value: "989X 元大-民生"},
{id:"989Y", value: "989Y 元大-基隆"},
{id:"989Z", value: "989Z 元大-小港"},
{id:"989a", value: "989a 元大-苑裡"},
{id:"989d", value: "989d 元大-北屯"},
{id:"989e", value: "989e 元大-中和"},
{id:"989f", value: "989f 元大-東港"},
{id:"989g", value: "989g 元大-嘉義"},
{id:"989j", value: "989j 元大-樹林"},
{id:"989k", value: "989k 元大-古亭"},
{id:"989m", value: "989m 元大-信義"},
{id:"989z", value: "989z 元大-國際證券"},
{id:"9A00", value: "9A00 永豐金"},
{id:"9A61", value: "9A61 永豐金-鳳山"},
{id:"9A69", value: "9A69 永豐金-屏東"},
{id:"9A79", value: "9A79 永豐金-埔里"},
{id:"9A88", value: "9A88 永豐金-國際證券"},
{id:"9A89", value: "9A89 永豐金-敦北"},
{id:"9A8F", value: "9A8F 永豐金-敦南"},
{id:"9A91", value: "9A91 永豐金-松山"},
{id:"9A92", value: "9A92 永豐金-萬盛"},
{id:"9A96", value: "9A96 永豐金-博愛"},
{id:"9A97", value: "9A97 永豐金-新竹"},
{id:"9A98", value: "9A98 永豐金-大園"},
{id:"9A99", value: "9A99 永豐金-中壢"},
{id:"9A9A", value: "9A9A 永豐金-羅東"},
{id:"9A9B", value: "9A9B 永豐金-中和"},
{id:"9A9C", value: "9A9C 永豐金-員林"},
{id:"9A9D", value: "9A9D 永豐金-忠孝"},
{id:"9A9E", value: "9A9E 永豐金-板橋"},
{id:"9A9G", value: "9A9G 永豐金-天母"},
{id:"9A9H", value: "9A9H 永豐金-新莊"},
{id:"9A9J", value: "9A9J 永豐金-板新"},
{id:"9A9K", value: "9A9K 永豐金-三重"},
{id:"9A9L", value: "9A9L 永豐金-台中"},
{id:"9A9M", value: "9A9M 永豐金-南投"},
{id:"9A9N", value: "9A9N 永豐金-桃盛"},
{id:"9A9P", value: "9A9P 永豐金-竹北"},
{id:"9A9Q", value: "9A9Q 永豐金-豐原"},
{id:"9A9R", value: "9A9R 永豐金-信義"},
{id:"9A9S", value: "9A9S 永豐金-南京"},
{id:"9A9U", value: "9A9U 永豐金-中正"},
{id:"9A9W", value: "9A9W 永豐金-市政"},
{id:"9A9X", value: "9A9X 永豐金-竹科"},
{id:"9A9Y", value: "9A9Y 永豐金-板盛"},
{id:"9A9Z", value: "9A9Z 永豐金-復興"},
{id:"9A9a", value: "9A9a 永豐金-苓雅"},
{id:"9A9b", value: "9A9b 永豐金-虎尾"},
{id:"9A9c", value: "9A9c 永豐金-永康"},
{id:"9A9d", value: "9A9d 永豐金-古亭"},
{id:"9A9e", value: "9A9e 永豐金-高雄"},
{id:"9A9f", value: "9A9f 永豐金-中盛"},
{id:"9A9g", value: "9A9g 永豐金-內湖"},
{id:"9A9h", value: "9A9h 永豐金-台南"},
{id:"9A9i", value: "9A9i 永豐金-新店"},
{id:"9A9j", value: "9A9j 永豐金-嘉義"},
{id:"9A9q", value: "9A9q 永豐金-潮州"},
{id:"9A9r", value: "9A9r 永豐金-北高雄"},
{id:"9A9s", value: "9A9s 永豐金-彰化"},
{id:"9A9x", value: "9A9x 永豐金-桃園"},       
  ];  
  
  export default Broker  