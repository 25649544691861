const SeasonAll2 = [
    {id: "1", value: "1季"}, 
    {id: "2", value: "2季"}, 
    {id: "3", value: "3季"}, 
    {id: "4", value: "4季"},
    {id: "5", value: "5季"},
    {id: "6", value: "6季"},    
    {id: "7", value: "7季"}, 
    {id: "8", value: "8季"}, 
    {id: "9", value: "9季"}, 
    {id: "10", value: "10季"},
    {id: "11", value: "11季"},
    {id: "12", value: "12季"},    
    {id: "13", value: "13季"},
    {id: "14", value: "14季"},
    {id: "15", value: "15季"},
    {id: "16", value: "16季"},
    {id: "17", value: "17季"},
    {id: "18", value: "18季"},
    {id: "19", value: "19季"},
    {id: "20", value: "20季"},                                
  ];  
  
  export default SeasonAll2 