const MRate = [
    {id: "80", value: "80"},
    {id: "90", value: "90"},
    {id: "95", value: "95"},
    {id: "100", value: "100"},
    {id: "105", value: "105"},
    {id: "110", value: "110"},
    {id: "115", value: "115"},
    {id: "120", value: "120"},
    {id: "125", value: "125"},
    {id: "130", value: "130"},
    {id: "135", value: "135"},
    {id: "140", value: "140"},
    {id: "145", value: "145"},
    {id: "150", value: "150"},
    {id: "155", value: "155"},
    {id: "160", value: "160"},
    {id: "165", value: "165"},
    {id: "170", value: "170"},
    {id: "175", value: "175"},
    {id: "180", value: "180"},
    {id: "185", value: "185"},
    {id: "190", value: "190"},
    {id: "195", value: "195"},
    {id: "200", value: "200"},
    {id: "205", value: "205"},
    {id: "210", value: "210"},
    {id: "250", value: "250"},               
  ];  
  
  export default MRate  