import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'
import CHome from './chome/CHome'
// import CGHome from './mhome/CHome'
// import IOSHome from './ioshome/CHome'

export default props => (
  // <Router>
  //     <Routes>
  //       <Route exact path='/' element={<CHome path={"home"} />} />
  //       <Route exact path='/ghome' element={<CHome path={"ghome"} />} />
  //       <Route exact path='/ioshome' element={<CHome path={"ioshome"} />} />
  //       <Route exact path='/open' element={<CHome />} />
  //     </Routes>
  // </Router>

    <HashRouter>
      <Routes>
        <Route exact path='/' element={<CHome path={"home"} />} />
        <Route exact path='/ghome' element={<CHome path={"ghome"} />} />
        <Route exact path='/ioshome' element={<CHome path={"ioshome"} />} />
        <Route exact path='/open' element={<CHome />} />
      </Routes>
    </HashRouter>
)