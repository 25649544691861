// import * as React from 'react';
import React, { useState, useEffect, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';


const findId = id => (element, index, array) => {
    return element.id === id;
}


export default function RuleTp4(props) {
    const [value1, setValue1] = React.useState(getDefault(0, 'v1'));
    const [value2, setValue2] = React.useState(getDefault(1, 'v2'));
    const [value3, setValue3] = React.useState(getDefault(2, 'v3'));
    const [value4, setValue4] = React.useState(getDefault(3, 'v4'));
    const [value5, setValue5] = React.useState(getDefault(4, 'v5'));
    const [value6, setValue6] = React.useState(getDefault(5, 'v6'));

    const [slist, setSlist] = React.useState([]);
    const [s1, setS1] = React.useState("");
    const [s2, setS2] = React.useState("");
    const [s3, setS3] = React.useState("");
    const [s4, setS4] = React.useState("");
    const [s5, setS5] = React.useState("");

    const [checked, setChecked] = React.useState(false);

    const initValue = () => {
        setValue1(getDefault(0, 'v1'))
        setValue2(getDefault(1, 'v2'))
        setValue3(getDefault(2, 'v3'))
        setValue4(getDefault(3, 'v4'))
        setValue5(getDefault(4, 'v5'))
        setValue6(getDefault(5, 'v6'))
        setSlist([])
        setS1("")
        setS2("")
        setS3("")
        setS4("")
        setS5("")

        setChecked(false)
    }

    const hasCheck = () => {
        let k = props.rules != null && props.rules.length > 0
        if(!k) setChecked(false)
        return k
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.ruleChange(props.row.id,
            genPayload(value1, value2, value3, value4, value5, value6, slist, s1, s2, s3, s4, s5),
            event.target.checked)
    };

    function genPayload(value1, value2, value3, value4, value5, value6, slist, s1, s2, s3, s4, s5) {
        if (props.row != null) {
            let a = { id: props.row.id }
            if (value1 != null && value1 != "") a['value1'] = value1.toString()
            if (value2 != null && value2 != "") a['value2'] = value2.toString()
            if (value3 != null && value3 != "") a['value3'] = value3.toString()
            if (value4 != null && value4 != "") a['value4'] = value4.toString()
            if (value5 != null && value5 != "") a['value6'] = value5.toString()
            if (value6 != null && value6 != "") a['value7'] = value6.toString()

            if (slist != null && slist.length > 0) a['value5'] = slist

            if (s1 != null && s1 != "") a['s1'] = s1
            if (s2 != null && s2 != "") a['s2'] = s2
            if (s3 != null && s3 != "") a['s3'] = s3
            if (s4 != null && s4 != "") a['s4'] = s4
            if (s5 != null && s5 != "") a['s5'] = s5

            return a
        } else return null
    }

    function getDefault(index, name) {
        if (props.row.value == null &&
            props.row.menu != null &&
            props.row.menu.length > index
            && props.row.menu[index].length > 0) {
            return props.row.menu[index][0].id
        } else if (props.row.value != null && props.row.value.indexOf(name) != -1 &&
            props.row.menu.length > props.row.value.indexOf(name) &&
            props.row.menu[props.row.value.indexOf(name)].length > 0
        ) {
            return props.row.menu[props.row.value.indexOf(name)][0].id
        } else return ""
    }

    useEffect(() => {
        if (props.rules != undefined) {
            let rule = props.rules.find(findId(props.row.id))
            if (rule != null) {
                if (rule.value1 != null && rule.value1 != "") setValue1(rule.value1)
                if (rule.value2 != null && rule.value2 != "") setValue2(rule.value2)
                if (rule.value3 != null && rule.value3 != "") setValue3(rule.value3)
                if (rule.value4 != null && rule.value4 != "") setValue4(rule.value4)
                if (rule.value6 != null && rule.value6 != "") setValue5(rule.value6)
                if (rule.value7 != null && rule.value7 != "") setValue6(rule.value7)

                if (rule.value5 != null && rule.value5.length > 0) setSlist(rule.value5)
                if (rule.s1 != null && rule.s1 != "") setS1(rule.s1)
                if (rule.s2 != null && rule.s2 != "") setS2(rule.s2)
                if (rule.s3 != null && rule.s3 != "") setS3(rule.s3)
                if (rule.s4 != null && rule.s4 != "") setS4(rule.s4)
                if (rule.s5 != null && rule.s5 != "") setS5(rule.s5)

                setChecked(true)
            } else {
                initValue()
            }
        } else {
            initValue()
        }
    }, []);


    const handleValue1Change = (event) => {
        setValue1(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(event.target.value, value2, value3, value4, value5, value6, slist, s1, s2, s3, s4, s5),
                checked)
        }
    };

    const handleValue2Change = (event) => {
        setValue2(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, event.target.value, value3, value4, value5, value6, slist, s1, s2, s3, s4, s5),
                checked
            )
        }
    };

    const handleValue3Change = (event) => {
        setValue3(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, event.target.value, value4, value5, value6, slist, s1, s2, s3, s4, s5), checked)
        }
    };

    const handleValue4Change = (event) => {
        setValue4(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, event.target.value, value5, value6, slist, s1, s2, s3, s4, s5), checked)
        }
    };

    const handleValue5Change = (event) => {
        setValue5(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, event.target.value, value6, slist, s1, s2, s3, s4, s5), checked)
        }
    };

    const handleValue6Change = (event) => {
        setValue6(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, event.target.value, slist, s1, s2, s3, s4, s5), checked)
        }
    };

    const handleValueSListChange = (event) => {
        setSlist(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, value6, event.target.value, s1, s2, s3, s4, s5), checked)
        }
    };

    const handleS1Change = (event) => {
        setS1(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, value6, slist, event.target.value, s2, s3, s4, s5),
                checked)
        }
    };

    const handleS2Change = (event) => {
        setS2(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, value6, slist, s1, event.target.value, s3, s4, s5),
                checked)
        }
    };

    const handleS3Change = (event) => {
        setS3(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, value6, slist, s1, s2, event.target.value, s4, s5),
                checked)
        }
    };

    const handleS4Change = (event) => {
        setS4(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, value6, slist, s1, s2, s3, event.target.value, s5),
                checked)
        }
    };

    const handleS5Change = (event) => {
        setS5(event.target.value);
        if (checked) {
            props.ruleChange(props.row.id,
                genPayload(value1, value2, value3, value4, value5, value6, slist, s1, s2, s3, s4, event.target.value),
                checked)
        }
    };


    const handeEvent = (t, e) => {
        if (t === "v1") handleValue1Change(e)
        else if (t === "v2") handleValue2Change(e)
        else if (t === "v3") handleValue3Change(e)
        else if (t === "v4") handleValue4Change(e)
        else if (t === "v5") handleValue5Change(e)
        else if (t === "s1") handleS1Change(e)
        else if (t === "s2") handleS2Change(e)
        else if (t === "s3") handleS3Change(e)
        else if (t === "s4") handleS4Change(e)
        else if (t === "s5") handleS5Change(e)        
    }

    const handeValue = (t, v, i) => {
        if (t == null) return v
        else if (t.length <= i) return null
        else if (t[i] === 'v1') return value1
        else if (t[i] === 'v2') return value2
        else if (t[i] === 'v3') return value3
        else if (t[i] === 'v4') return value4
        else if (t[i] === 'v5') return value5
        else if (t[i] === 's1') return s1
        else if (t[i] === 's2') return s2
        else if (t[i] === 's3') return s3
        else if (t[i] === 's4') return s4
        else if (t[i] === 's5') return s5        
        else return null
    }

    const mkMenu = (data) => {
        if (data.ig == null) return <MenuItem key={data.id} value={data.id} >{data.value}</MenuItem>
        else return <MenuItem key={data.id} value={data.id} ><img src={data.ig} />{data.value}</MenuItem>
    }

    const descNull = props.row.desc != null
    const descLength = descNull ? props.row.desc.length : 0
    const menuNull = props.row.menu != null
    const menuLength = menuNull ? props.row.menu.length : 0

    const render1 = (index, v, h) => {
        if (menuNull && menuLength > index) {
            if (props.row.menu[index] != null && props.row.menu[index].length > 0) {
                return <Select
                    value={handeValue(props.row.value, v, index)}
                    onChange={e => {
                        if (props.row.value == null) {
                            h(e)
                        } else if (props.row.value.length > index) {
                            handeEvent(props.row.value[index], e)
                        }
                    }}
                    variant="standard"
                    style={{ textAlign: 'center', minWidth: 60, marginLeft: 5, fontSize: 12 }}
                >
                    {props.row.menu[index].map(data => { return mkMenu(data) })}
                </Select>
            } else if (props.row.menu[index] != null && props.row.menu[index].length === 0) {
                return <TextField
                    size="small"
                    inputProps={{ style: { textAlign: 'center' } }}
                    style={{ width: 90, fontSize: 12, marginLeft: 5 }}
                    id="filled-name1"
                    value={v}
                    onChange={e => h(e)}
                />
            } else return null
        } else return null
    }

    return (
        // , borderBottomStyle: 'solid' <Box display="flex" pl={2} pr={2} pt={2} pb={2} borderBottom={1} style={{ borderColor: "#e0e0e0", alignItems: 'center' }}>
        <div style={{ borderWidth: 1, borderBottomStyle: 'solid', padding: 15, display: "flex", borderColor: "#e0e0e0", alignItems: 'center' }}>
            <Checkbox
                color="secondary"
                checked={checked && hasCheck()}
                onChange={e => handleChange(e)}
                inputProps={{ 'aria-label': 'controlled' }}
                style={{ color: "#54A0CC", paddingLeft: 0, paddingRight: 0 }}
            />
            {props.row.img != null ? <Avatar src={props.row.img} style={{ marginLeft: 10, width: 40, height: 40 }} /> : null}

            <Typography variant="subtitle2" style={{ marginLeft: 20 }}>
                {descNull && descLength > 0 ? props.row.desc[0] : null}
                {render1(0, value1, handleValue1Change)}
                {props.row.stext != null && props.row.stext.length > 0 && props.row.stext[0] === 1 ?<TextField
                    size="small"
                    inputProps={{ style: { textAlign: 'center' } }}
                    style={{ width: 90, fontSize: 12, marginLeft: 5 }}
                    id="filled-name1"
                    value={s1}
                    onChange={e => handleS1Change(e)}
                />: null}

                {props.row.menu1 != null && props.row.menu1.length > 0 && props.row.menu1[0].length > 0 ? <Select
                    id="value1m-select"
                    multiple
                    value={slist}
                    onChange={e => handleValueSListChange(e)}
                    variant="standard"
                    style={{ width: 180, marginLeft: 5, fontSize: 12 }}
                >
                    {props.row.menu1[0].map(data => { return mkMenu(data) })}
                </Select> : null}

                {descNull && descLength > 1 ? props.row.desc[1] : null}
                {render1(1, value2, handleValue2Change)}
                {props.row.stext != null && props.row.stext.length > 1 && props.row.stext[1] === 1 ?<TextField
                    size="small"
                    inputProps={{ style: { textAlign: 'center' } }}
                    style={{ width: 90, fontSize: 12, marginLeft: 5 }}
                    id="filled-name1"
                    value={s2}
                    onChange={e => handleS2Change(e)}
                />: null}


                {descNull && descLength > 2 ? props.row.desc[2] : null}
                {render1(2, value3, handleValue3Change)}
                {props.row.stext != null && props.row.stext.length > 2 && props.row.stext[2] === 1 ?<TextField
                    size="small"
                    inputProps={{ style: { textAlign: 'center' } }}
                    style={{ width: 90, fontSize: 12, marginLeft: 5 }}
                    id="filled-name1"
                    value={s3}
                    onChange={e => handleS3Change(e)}
                />: null}


                {descNull && descLength > 3 ? props.row.desc[3] : null}
                {render1(3, value4, handleValue4Change)}
                {props.row.stext != null && props.row.stext.length > 3 && props.row.stext[3] === 1 ?<TextField
                    size="small"
                    inputProps={{ style: { textAlign: 'center' } }}
                    style={{ width: 90, fontSize: 12, marginLeft: 5 }}
                    id="filled-name1"
                    value={s4}
                    onChange={e => handleS4Change(e)}
                />: null}

                {descNull && descLength > 4 ? props.row.desc[4] : null}
                {render1(4, value5, handleValue5Change)}
                {descNull && descLength > 5 ? props.row.desc[5] : null}
                {render1(5, value6, handleValue6Change)}
                {descNull && descLength > 6 ? props.row.desc[6] : null}

                {props.row.sh != null ? <div style={{ fontSize: 10, color: "#a3a3a3" }}>{props.row.sh}</div> : null}
                {props.row.prv ? <Chip style={{ marginLeft: 5, fontSize: 12 }} color={'default'} size="small" label="訂閱專屬" /> : null}
                {props.row.new ? <Chip style={{ marginLeft: 5, fontSize: 12, backgroundColor: "#fa2d45", color: "#FFFFFF" }} size="small" label="新" /> : null}
            </Typography>
        </div>
    );
}